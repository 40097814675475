<template>
  <div class="father_div">
    <el-row :gutter="25">
      <el-col :md="8" v-for="(item, i) in news" :key="i">
        <div class="news_item_div" @click="pushTo('/NewsDetail/id=' + item.id)">
          <div class="img_div">
            <video
              v-if="item.cover.includes('.mp4')"
              muted
              autoplay
              loop="loop"
            >
              <source :src="item.cover" type="video/mp4" />
            </video>
            <img v-else :src="item.cover" />
            <div class="cover_div"></div>
          </div>
          <div class="item_div">
            <p class="p1">{{ item.time }}</p>
            <p class="p2">{{ item.title }}</p>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import * as API from "@/api/index";
export default {
  props: ["pageSize"],
  data() {
    return {
      news: [],
      isOpen: false,
    };
  },
  mounted() {
    this.getNews();
  },
  methods: {
    getNews() {
      var data = {
        page: 1,
        limit: this.pageSize,
      };
      API.getProperty(data)
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.news = res.page.list;
          }
        })
        .catch(() => {});
    },
    pushTo(path) {
      this.$router.push(path);
    },
  },
};
</script>
<style lang="scss" scoped>
.father_div {
  margin-top: 5rem;
}
@media (max-width: 1000px) {
  .father_div {
    margin-top: 0;
    padding: 0 2.95rem;
  }
}
</style>