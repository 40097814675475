import Vue from 'vue'
import VueI18n from 'vue-i18n'
import App from './App.vue'
import router from './router'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import '@/assets/css/base.scss'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 引入各个语言配置文件
import zh from './language/zh';
import en from './language/en';
import VueTouch from 'vue-touch';
Vue.use(VueTouch, { name: 'v-touch' });

Vue.config.productionTip = false;
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'zh', // 语言标识
  //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    en: en, // 英文
    zh: zh, // 中文
  }
});
ElementUI.i18n((key, value) => i18n.t(key, value));
Vue.use(ElementUI);
export default i18n;
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'D4cxhpDLAmKTeI9tTCBSX730k3SbpmFn'
})


AOS.init();
new Vue({
  i18n, // 国际化挂载
  el: '#app',
  router,
  render: h => h(App)
})
