<template>
  <div>
    <div
      class="width_div relative between flex column end padding_top_120 padding_bottom_50"
    >
      <div class="title_div">
        <p class="p1">{{ data.title }}</p>
        <p class="p2">{{ data.simple }}</p>
      </div>
    </div>
    <div class="swiper_bg">
      <div class="swiper-container mySwiper viewSwiper" id="viewSwiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in zzry" :key="index">
            <img class="banner-img" :src="item.img" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper, { Autoplay } from "swiper";
Swiper.use(Autoplay);
import "swiper/swiper-bundle.css";
import * as API from "@/api/index";
export default {
  watch: {
    //监听轮播数据变化后再创建实例
    zzry() {
      setTimeout(() => {
        this.initSwiper();
      }, 1000);
    },
  },
  data() {
    return {
      data: {},
      isOpen: false,
      swiper: Object,
      bannertext: "",
      selectPosition: 0,
      zzry: [],
    };
  },
  mounted() {
    this.getInvestment();
  },
  methods: {
    getInvestment() {
      API.getInvestment()
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.data = res.page.list[0];
            this.zzry = JSON.parse(this.data.sub);
          }
        })
        .catch(() => {});
    },
    pushTo(path) {
      this.$router.push(path);
    },
    initSwiper() {
      let that = this;
      this.$nextTick(() => {
        that.swiper = new Swiper(".viewSwiper", {
          slidesPerView: "auto",
          centeredSlides: true,
          loop: true,
          spaceBetween: 10,
          autoplay: {
            disableOnInteraction: false,
            delay: 3000,
          },
          breakpoints: {
            600: {
              spaceBetween: 10,
            },
            1200: {
              spaceBetween: 25,
            },
          },
        });
      }, 600);
    },
  },
};
</script>
<style lang="scss" scoped>
.title_div {
  display: flex;
  .p1 {
    text-align: start;
    font-size: 3.6rem;
    letter-spacing: -1px;
    width: 50%;
  }
  .p2 {
    width: 50%;
    line-height: 3.6rem;
    text-align: justify;
  }
}
.swiper_bg {
  margin-top: 8rem;
}
.banner-img {
  width: 100%;
  // height: 60rem;
  aspect-ratio: 16/9;
  object-fit: cover;
  border-radius: 2rem;
}
/deep/ .swiper-slide {
  width: 56.5%;
  transform: translateX(10%);
}
@media (max-width: 1000px) {
  .width_div {
    &.padding_top_120 {
      padding-top: 5.7rem;
    }
    &.padding_bottom_50 {
      padding-bottom: 2.3rem;
    }
  }
  .title_div {
    flex-direction: column;
    padding-left: 2.7rem;
    .p1 {
      width: 100%;
      font-size: 1.7rem;
    }
    .p2 {
      margin-top: 1rem;
      font-size: 1.1rem;
      line-height: 2.1rem;
      width: 100%;
    }
  }
  .swiper_bg {
    margin-top: 0;
    margin: 0 15px;
  }
  .banner-img {
    border-radius: 1rem;
  }
  /deep/ .swiper-slide {
    width: 71.2%;
    transform: none;
  }
}
</style>