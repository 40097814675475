<template>
  <div class="swiper_bg">
    <!-- <div class="tag_div home_div title_padding">
      <el-tag
        class="about_touch_box"
        :class="selectPosition == index ? 'cover' : ''"
        v-for="(item, index) in zzry"
        :key="index"
        @click="slideTo(index)"
      >
        {{ $i18n.locale == "zh" ? item.title : item.titleEn }}
      </el-tag>
    </div> -->
    <div class="middle_banner">
      <!-- <div class="swiper-button-prev swiper-button-prev1" @click="slideToPrev">
        <img src="../../static/img/btn-left-img-gary.png" class="bannerleft" /> 
      </div> -->
      <div class="swiper-container mySwiper mySwiper2" id="mySwiper2">
        <div class="swiper-wrapper">
          <!-- Slides -->
          <!-- <div class="swiper-slide" v-for="(item, index) in List" :key="index"> -->
          <div
            class="swiper-slide swiper-slide3"
            v-for="(item, index) in zzry"
            :key="index"
          >
            <!-- <img src="../../static/img/3A证书.png" /> -->
            <!-- <p class="title">{{ item.id }}</p> -->
            <div class="div3">
              <img class="book3_img" :src="item.img" />
              <div class="txt_div">
                <div class="p-div">
                  <p class="title3">
                    {{ $i18n.locale == "zh" ? item.title : item.titleEn }}
                  </p>
                  <p
                    class="content3"
                    v-html="
                      $i18n.locale == 'zh' ? item.smallTitle : item.smallTitleEn
                    "
                  ></p>
                </div>
              </div>
              <!-- <p>{{ index }}</p> -->
            </div>

            <!-- <span class="content content2">{{ item.title }}</span> -->
          </div>
        </div>
        <!-- <div class="banner-line2"></div> -->
        <div class="swiper-pagination"></div>
      </div>
      <!-- <div class="swiper-button-next swiper-button-next1" @click="slideToNext">
       <img
          src="../../static/img/btn-right-img-gary.png"
          class="bannerright"
        /> 
      </div> -->
    </div>
    <!-- <p class="swiper_title">{{ bannertext }}</p> -->
  </div>
</template>
<script>
import * as API from "@/api/index";
import Swiper, { Pagination } from "swiper";
Swiper.use(Pagination);
import "swiper/swiper-bundle.css";
export default {
  props: {
    language: {
      type: String,
      default: "",
    },
  },
  watch: {
    //监听轮播数据变化后再创建实例
    zzry() {
      // this.initSwiper();
      setTimeout(() => {
        //   this.initSize();
        // this.$nextTick(() => {
        let that = this;
        this.initSwiper();
      }, 1000);
    },
  },
  data() {
    return {
      swiper3: Object,
      bannertext: "",
      selectPosition: 0,
      zzry: [],
    };
  },
  mounted() {
    this.getInfo();
  },
  beforeDestroy() {},
  created() {},
  methods: {
    getInfo() {
      API.getIndustry()
        .then((res) => {
          console.log(res);
          var status = res.code;
          if (status === 0) {
            this.zzry = res.page.list;
          }
        })
        .catch(() => {});
    },
    initSwiper() {
      let that = this;
      this.$nextTick(() => {
        that.swiper3 = new Swiper(".mySwiper2", {
          slidesPerView: "auto",
          centeredSlides: true,
          // loop: true,
          // autoplay: {
          //   disableOnInteraction: false,
          //   delay: 3000,
          // },
          pagination: {
            el: ".swiper-pagination",
          },
          on: {
            activeIndexChange: function () {
              // console.log("改变了，activeIndex为" + this.activeIndex);
            },
            slideChange: function () {
              // console.log("slideChange==" + this.activeIndex);
              // console.log("slideChange real==" + this.realIndex);
              that.changetext(this.realIndex);
            },
          },
        });
      }, 600);
    },
    changetext(i) {
      this.selectPosition = i;
    },
    slideTo(i) {
      // console.log('click slide to==',i)
      // this.swiper3.slideTo(i);//非loop使用
      this.swiper3.slideToLoop(i); //loop 下使用
    },
    slideToNext() {
      this.swiper3.slideNext();
    },
    slideToPrev() {
      this.swiper3.slidePrev();
    },
  },
};
</script>
<style lang="scss" scoped>
.middle_banner {
  max-width: 1920px;
  margin-top: 10rem;
}
.tag_div {
  // padding: 5rem 0;
  display: flex;
  margin: 5rem auto 0 !important;
}
.about_touch_box {
  margin: 1rem;
  font-family: demibold;
  font-size: 2.4rem;
  border: $textGaryColor solid 1px;
  border-radius: 5rem;
  padding: 0 4rem;
  width: fit-content;
  cursor: pointer;
  transition: all 0.5s;
  background: white;
  color: $textGaryColor;

  &:hover {
    transition: all 0.5s;
    border: $textBlueColor solid 1px;
    background-color: $textBlueColor;
    color: white;
  }
  &.cover {
    transition: all 0.5s;
    border: $textBlueColor solid 1px;
    background-color: $textBlueColor;
    color: white;
  }
}
.swiper-slide {
  width: 90%;
  text-align: center;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

// .swiper-slide img {
// display: block;
// width: 100%;
// height: 100%;
// object-fit: cover;
// }
.banner-line2 {
  background: white;
  height: 40px;
  width: 100%;
  /* top: 16.3rem; */
  margin: 0 -5rem;
  position: absolute;
  bottom: 0;
}

.div3 {
  width: 100%;
  height: 72rem;
  // display: flex;
  // background: #ffffffcc;
  padding: 8rem 3rem;
  // justify-content: center;
  // align-items: flex-end;
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: center;
  // height: 72rem;
  border-radius: 4rem;
  // box-sizing: border-box;
  position: relative;

  .book3_img {
    width: 100%;
    height: 100%;
    // object-fit: cover;
    border-radius: 2rem;
  }
  .txt_div {
    width: 35%;
    position: absolute;
    // width: 157rem;
    bottom: 18rem;
    left: 7rem;
  }

  .p-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: start;
    transition: all 0.3s;
    background: $textWhiteColor75;
    border-radius: 3rem;
    padding: 4.8rem;
    box-sizing: border-box;
  }

  .title3 {
    color: $textBlueColor;
    font-size: 2.4rem;
    font-family: bold;
  }

  .content3 {
    margin-top: 2rem;
    font-size: 1.6rem;
    font-family: normal;
    color: $color4D;
  }

  .num3 {
    font-size: 2.8rem;
    color: #555;
    font-family: medium;
    position: absolute;
    bottom: 5rem;
    right: 4rem;
  }

  .num-active {
    color: #555;
  }
}

.swiper-button-prev1 {
  left: 20%;
}

.swiper-button-next1 {
  right: 20%;
}

.swiper-slide3 {
  opacity: 0.5;
}

.swiper-slide-active {
  opacity: 1;

  .num-active {
    color: #004498;
  }
}
@media (max-width: 1000px) {
  .middle_banner {
    margin-top: 40px;
  }
  .swiper-slide {
    width: 85%;
  }
  .tag_div {
    width: 100% !important;
    margin-top: 0 !important;
    display: block;
  }
  .about_touch_box {
    font-family: normal;
    min-width: 12rem;
    font-size: 2rem;
    padding: 0 2rem;
  }
  .div3 {
    position: relative;
    padding: 3rem 1rem;
    height:auto;

    .book3_img {
      height: 65vw;
      object-fit: cover;
    }
    .title3 {
      font-family: medium;
      font-size: 3.8rem;
      color: $textGaryColor;
    }
    .content3 {
      font-size: 2.6rem;
      color: $textGaryColor;
    }
    .p-div {
      padding: 3rem 0;
      background: transparent;
    }
    .txt_div {
      position: relative;
      width: 100%;
      left: 0;
      bottom: 0;
    }
  }
}
</style>
