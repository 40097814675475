<template>
  <div class="carousel_father">
    <div class="swiper-container mySwiper mySwiper1 outside_div" id="mySwiper1">
        <div class="swiper-wrapper">
          <!-- Slides -->
          <div class="swiper-slide" v-for="(item, index) in List" :key="index">
            <div class="home_banner_div" @click="jumpTo(item.url)">
            <video v-if="item.img.includes('.mp4')" muted autoplay loop="loop" style="width: 100%;height: 100%;object-fit: cover">
              <source
                :src="isMiniWindows ? item.phoneImg : item.img"
                type="video/mp4"
              />
            </video>
            <img v-else :src="isMiniWindows ? item.phoneImg : item.img" />
            <!-- <div class="home_banner_txt_div">
              <p
                class="title_1"
                v-html="$i18n.locale == 'zh' ? item.title : item.titleEn"
              ></p>
              <p
                class="title_2"
                v-html="$i18n.locale == 'zh' ? item.content : item.contentEn"
              ></p>
            </div> -->
          </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>

      </div>
      <div class="home_banner_txt_div">
              <p
                class="title_1"
                v-html="$i18n.locale == 'zh' ? List[activeIndex].title : List[activeIndex].titleEn"
              ></p>
              <p
                class="title_2"
                v-html="$i18n.locale == 'zh' ? List[activeIndex].content : List[activeIndex].contentEn"
              ></p>
            </div>
    <!-- <div class="calc_div" v-if="!isMiniWindows">
      <div class="calc_sub_div_left" @click="swiperleft()"></div>
      <div class="calc_sub_div" @click="jumpTo(List[activeIndex].url)"></div>
      <div class="calc_sub_div_right" @click="swiperright()"></div>
    </div> -->
  </div>
</template>
<script>
import * as API from "@/api/index";
import Swiper, { Pagination ,Autoplay} from "swiper";
Swiper.use(Pagination);
Swiper.use(Autoplay);
export default {
  data() {
    return {
      List: [{title:'',titleEn:'',content:'',contentEn:'',url:'',img:'',phoneImg:''}],
      isMiniWindows: false,
      clientWidth: 0,
      activeIndex: 0,
      swiper1: Object,
    };
  },
  created() {},
  watch: {
    $route(to, from) {
      if (to.path == "/") {
        // this.getBannerList();
        // this.initSwiper();
        if(this.swiper1){
          console.log('swiper1====')
          this.swiper1.slideToLoop(0,0);
          // this.swiper1.autoplay.start();
        }
      }
    },
    clientWidth(newVal, oldVal) {
      // console.log(newVal); //浏览器窗口变化时，打印宽度。
      if (newVal < 1000) {
        this.isMiniWindows = true;
      } else {
        this.isMiniWindows = false;
      }
    },
    //监听轮播数据变化后再创建实例
    List() {
      this.$nextTick(() => {
        this.initSwiper();
      });
    },
  },
  computed: {},
  mounted() {
    this.getBannerList();
    let that = this;
    var clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    that.clientWidth = clientWidth;
    window.onresize = () => {
      return (() => {
        var clientWidth =
          document.documentElement.clientWidth || document.body.clientWidth;
        that.clientWidth = clientWidth;
      })();
    };
  },
  destroyed() {},
  methods: {
    getBannerList() {
      API.getHomeBanner()
        .then((res) => {
          console.log(res);
          var status = res.code;
          if (status === 0) {
            this.List = res.page.list;
          }
        })
        .catch(() => {});
    },
    initSwiper() {
      let that = this;
      this.$nextTick(() => {
        that.swiper1 = new Swiper(".mySwiper1", {
          slidesPerView: 1,
          centeredSlides: true,
          loop: true,
          observer:true,
          observeParents:true,
          autoplay: {
            disableOnInteraction: false,
            delay: 4000,
          },
          pagination: {
            el: ".swiper-pagination",
          },
          on: {
            activeIndexChange: function () {
              // console.log("改变了，activeIndex为" + this.activeIndex);
            },
            slideChange: function () {
              // console.log("slideChange==" + this.activeIndex);
              // console.log("slideChange real==" + this.realIndex);
              that.setActiveIndex(this.realIndex);
            },
          },
        });
      }, 600);
    },
    jumpTo(url) {
      if (url) {
        window.open(url, "_blank");
      }
    },
    navTo(path) {
      this.$router.push(path);
    },
    //设置滑动切换轮播图
    swiperleft: function (index) {
      //上一页
      // this.$refs.carousel.prev();
      this.swiper1.slidePrev();
      //设置幻灯片的索引
      // this.$refs.carousel.setActiveItem(index - 1);
    },
    swiperright: function (index) {
      //下一页
      // this.$refs.carousel.next();
      this.swiper1.slideNext();
      // this.$refs.carousel.setActiveItem(index + 1);
    },
    setActiveIndex(nowindex, oldindex) {
      // console.log("now==", nowindex);
      // console.log("old==", oldindex);
      this.activeIndex = nowindex;
    },
  },
};
</script>
<style lang="scss" scoped>
.carousel_father {
  height: 108rem;
  width: 100%;
  z-index: 0;
  position: relative;
}
.calc_div {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  z-index: 100;
  .calc_sub_div {
    width: 33.33%;
    height: 100%;
    cursor: $point_3, auto;
  }
  .calc_sub_div_left {
    @extend .calc_sub_div;
    cursor: $point_7, auto;
  }
  .calc_sub_div_right {
    @extend .calc_sub_div;
    cursor: $point_8, auto;
  }
}
.wrapper {
  height: 100%;
}

.outside_div {
  height: 100%;
  width: 100%;
  /deep/ .el-carousel__item.is-animating {
    transition: transform 0.3s ease-in-out;
  }
  /deep/ .el-carousel__indicator--horizontal {
    cursor: $point_1, pointer !important;
    padding: 12px 0;
  }
  /deep/.el-carousel__indicators--horizontal {
    bottom: 7.5rem;
    right: 8rem;
    left: unset;
    // padding: 12px 20px;
    width: max-content;
    transform: none;
  }

  /deep/.el-carousel__button {
    cursor: $point_1, pointer !important;
    width: 1.8rem;
    border-radius: 50%;
    height: 1.8rem;
    margin: 0 1rem;
    opacity: 1;
    background-color: $textWhiteColor30;
    overflow: hidden;
    // transition: all 0.5s;
  }

  /deep/.el-carousel__indicator.is-active button {
    background-color: white;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    // transition: all 0.5s;
    // width: 22.5rem;
  }
}
/deep/ .el-carousel__arrow {
  &:hover {
    background-color: transparent;
  }
}
/deep/ .el-carousel__arrow--left {
  background-image: url("../../../static/img/banner_left_icon.png");
  background-size: contain;
  height: 6.5rem;
  background-color: transparent;
  background-repeat: no-repeat;
  width: 3rem;
  border-radius: unset;
  left: 15rem;
  i {
    display: none;
  }
}
/deep/ .el-carousel__arrow--right {
  background-image: url("../../../static/img/banner_right_icon.png");
  background-size: contain;
  height: 6.5rem;
  background-color: transparent;
  background-repeat: no-repeat;
  width: 3rem;
  border-radius: unset;
  right: 15rem;
  i {
    display: none;
  }
}
.swiper-container-horizontal > .swiper-pagination-bullets{
  bottom: 7.5rem;
    right: 8rem;
    width: 85%;
    left: unset;
    text-align: right;
    z-index: 120;
}
/deep/ .swiper-pagination-bullet{
  cursor: $point_1, pointer !important;
    width: 1.8rem;
    // border-radius: 50%;
    height: 1.8rem;
    margin: 0 1rem !important;
    opacity: 1;
    background-color: $textWhiteColor30;
    overflow: hidden;
}
/deep/ .swiper-pagination-bullet-active{
  background-color: white;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.home_banner_div {
  position: relative;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

}
.home_banner_txt_div {
    z-index: 90;
    position: absolute;
    margin: 0 auto;
    max-width: 1920px;
    width: 85%;
    position: absolute;
    left: 8rem;
    right: unset;
    bottom: 7.5rem;

    .title_1 {
      color: #fff;
      // letter-spacing: 1px;
      text-align: start;
      font-size: 2.4rem;
      margin-bottom: 1rem;
      line-height: 2.9rem;
    }

    .title_2 {
      letter-spacing: -1px;
      color: #fff;
      text-align: start;
      font-size: 3.6rem;
      line-height: 4.3rem;
      // margin-top: 1rem;
      // margin-bottom: 2rem;
    }
  }
@media screen and (max-width: 1000px) {
  .carousel_father {
    height: 64.9rem;
  }
  .home_banner_txt_div {
      // left: 5rem;
      width: 100%;
      left: 4.2rem;
      bottom: 7rem;

      .title_1 {
        color: white;
        line-height: 2.7rem;
        font-size: 1.5rem;
        margin-bottom: 0;
      }

      .title_2 {
        color: white;
        font-size: 2.3rem;
        line-height: 1.5;
      }
    }
  .home_banner_div {
    // img {
    // object-fit: contain;
    // }


    .more_touch_box_b_3E {
      font-size: 2.2rem;
      border: #1961ac solid 1px !important;
      border-radius: 5rem;
      padding: 1rem 4rem;
      width: fit-content;
      color: white;
      cursor: $point_1, pointer;
      transition: all 0.5s;
      background-color: #1961ac !important;
    }
  }

  .outside_div {
    /deep/.el-carousel__indicators--horizontal {
      bottom: 5.7rem;
      left: 4.2rem;
    }
    /deep/.el-carousel__button {
      width: 1rem;
      height: 1rem;
      margin: 0 0.6rem;
      // opacity: 1;
      // background-color: white;
    }

    /deep/.el-carousel__indicator.is-active button {
      // background-color: #1961ac;
      // width: 12.5rem;
    }
  }
  /deep/ .el-carousel__arrow {
    display: none;
  }

  .swiper-container-horizontal > .swiper-pagination-bullets{
    bottom: 4rem;
    right: unset;
    width: 85%;
    left: 4.2rem;
    text-align: start;
    z-index: 120;
}
/deep/ .swiper-pagination-bullet{
  cursor: $point_1, pointer !important;
    width: 1rem;
    // border-radius: 50%;
    height: 1rem;
    margin: 0 0.6rem !important;
    opacity: 1;
    background-color: $textWhiteColor30;
    overflow: hidden;
}
/deep/ .swiper-pagination-bullet-active{
  background-color: white;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
}
</style>
