var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"nav_father_out_div",class:[_vm.pcBarOpenState||_vm.drawer ? 'pcBarOpen' : ''],on:{"click":function($event){return _vm.pcBarOpenClose()}}}),_c('div',{staticClass:"nav_father white_bg",class:[
      !_vm.isMiniWindows ? (_vm.active_nav != 0 ? 'isHover' : '') : '',
      _vm.search_result.length > 0 ? 'hasResult' : '',
      !_vm.pcBarOpenState && !_vm.isMiniWindows && _vm.isHide ? 'mini' : '',
      _vm.drawer ? 'menu' : '' ],attrs:{"id":"nav_father"},on:{"mouseenter":function($event){return _vm.navEnter()},"mouseleave":function($event){return _vm.navLeave()}}},[_c('div',{staticClass:"nav_search",class:[
        _vm.pcBarOpenState ? 'pcBarOpen' : '',
        _vm.search_result.length > 0 ? 'hasResult' : '' ]},[_c('div',{staticClass:"search_top_div"},[_c('el-input',{attrs:{"placeholder":"键入搜索..."},on:{"input":_vm.querySearch},model:{value:(_vm.searchKey),callback:function ($$v) {_vm.searchKey=$$v},expression:"searchKey"}}),_c('img',{attrs:{"src":_vm.search_close_img},on:{"click":function($event){return _vm.pcBarOpen()}}})],1),_c('div',{staticClass:"result_p_div"},_vm._l((_vm.search_result),function(item,i){return _c('p',{key:i,staticClass:"result_p",on:{"click":function($event){_vm.searchBarNavto(
              (item.type == 1 ? '/WorksDetail/id=' : '/NewsDetail/id=') +
                item.id
            )}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),0),_c('p',{staticClass:"search_logo"},[_vm._v("© 2024 Deno Brand Planning")])]),_c('div',{staticClass:"my_nav",class:[
        _vm.pcBarOpenState ? 'pcBarOpen' : '',
        _vm.navMenuIndex != -1 || _vm.pcBarOpenState ? 'border' : '' ]},[_c('div',{staticClass:"nav_logo_div",on:{"click":function($event){return _vm.navtoTop('/')},"mouseenter":function($event){return _vm.navLeave()}}},[_c('img',{attrs:{"src":!_vm.isMiniWindows
              ? require('../../../static/img/logo.svg')
              : _vm.navEnterState || _vm.showWhiteNav
              ? require('../../../static/img/logo.svg')
              : require('../../../static/img/logo.svg')}})]),_c('div',{staticClass:"ul_div"},[_c('ul',{class:_vm.$i18n.locale != 'zh' ? 'en' : '',attrs:{"id":"ul_header"},on:{"mouseleave":function($event){return _vm.navMenuLeave()}}},_vm._l((_vm.nav_title_list),function(item,i){return _c('li',{key:item.title,staticClass:"nav_home",class:[_vm.pcBarOpenState ? 'en' : '']},[_c('a',{class:[_vm.active_nav == i ? 'active' : ''],attrs:{"id":'ul_header' + i},on:{"mouseenter":function($event){return _vm.navMenuEnter(i)},"click":function($event){return _vm.navto(item.path)}}},[_vm._v(_vm._s(item.title))])])}),0),_c('div',{staticClass:"icon-li"},[_c('div',{staticClass:"img_div",on:{"click":function($event){return _vm.openSider()}}},[(_vm.isMiniWindows)?_c('img',{attrs:{"src":_vm.drawer ? _vm.barCloseImg : _vm.barMenuImg}}):_vm._e()]),_c('div',{staticClass:"img_div",on:{"click":function($event){return _vm.pcBarOpen()}}},[_c('img',{attrs:{"src":_vm.pcBarOpenState ? _vm.search_close_img : _vm.search_img}})])])])]),_c('div',{staticClass:"phone_ul"},_vm._l((_vm.nav_title_list),function(item){return _c('p',{key:item.title,on:{"click":function($event){return _vm.navto(item.path)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }