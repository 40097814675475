import i18n from "../../main"
export function getList() {
  var title = i18n.t('test')
  console.log('navbar getList==', title)
  var list = [
    // {
    //   title: i18n.t("首页"),
    //   path: "/",
    //   children: [
    //     { title: i18n.t("关于我们"), path: "/About", children: [], tag: '' },
    //     { title: i18n.t("产品中心"), path: "/Products", children: [], tag: '' },
    //     { title: i18n.t("新闻资讯"), path: "/News", children: [], tag: '' },
    //     { title: i18n.t("联系我们"), path: "/CallUs", children: [], tag: '' },
    //   ],
    // },
    {
      title: i18n.t("作品"),
      path: "/Works",
      power: 1,
      children: [
        {
          title: i18n.t("化工版块"),
          img: require("../../../static/img/bar_5.png"),
          blue_img: require("../../../static/img/bar_5.png"),
          path: "/Products", children: [], tag: 'Chemical'
        },
        {
          title: i18n.t("投资版块"),
          img: require("../../../static/img/bar_6.png"),
          blue_img: require("../../../static/img/bar_6.png"),
          path: "/Products", children: [
            { title: i18n.t("股权投资"), },
            { title: i18n.t("实业投资"), },

          ], tag: 'Investment'
        },
        {
          title: i18n.t("置业版块"),
          img: require("../../../static/img/bar_7.png"),
          blue_img: require("../../../static/img/bar_7.png"),
          path: "/Products", children: [], tag: 'Property'
        },
      ],
    },
    {
      title: i18n.t("关于我们"),
      path: "/About",
      power: 1,
      children: [
        {
          title: i18n.t("集团概况"),
          img: require("../../../static/img/bar_1.png"),
          blue_img: require("../../../static/img/bar_1.png"),
          path: "/About",
          children: [],
          tag: 'Group'
        },
        {
          title: i18n.t("组织架构"),
          img: require("../../../static/img/bar_2.png"),
          blue_img: require("../../../static/img/bar_2.png"),
          path: "/About",
          children: [],
          tag: 'Organizational'
        },
        {
          title: i18n.t("企业文化"),
          img: require("../../../static/img/bar_3.png"),
          blue_img: require("../../../static/img/bar_3.png"),
          path: "/About",
          children: [],
          tag: 'Enterprise'
        },
        {
          title: i18n.t("发展历程"),
          img: require("../../../static/img/bar_4.png"),
          blue_img: require("../../../static/img/bar_4.png"),
          path: "/About",
          children: [],
          tag: 'Development'
        },
        // {
        //   title: i18n.t("产品证书"),
        //   img: require("../../../static/img/nav_about_icon_5.png"),
        //   blue_img: require("../../../static/img/nav_about_icon_5_1.png"),
        //   path: "/About",
        //   children: [],
        //   tag: 'Certificates'
        // },
      ],
    },

    {
      title: i18n.t("新闻资讯"),
      path: "/News",
      power: 1,
      children: [
        {
          title: i18n.t("集团新闻"),
          img: require("../../../static/img/bar_8.png"),
          blue_img: require("../../../static/img/bar_8.png"),
          path: "/News", children: [], tag: '1'
        },
        {
          title: i18n.t("行业动态"),
          img: require("../../../static/img/bar_9.png"),
          blue_img: require("../../../static/img/bar_9.png"),
          path: "/News", children: [], tag: '2'
        },
        {
          title: i18n.t("政策法规"),
          img: require("../../../static/img/bar_10.png"),
          blue_img: require("../../../static/img/bar_10.png"),
          path: "/News", children: [], tag: '3'
        },
      ],
    },
    {
      title: i18n.t("联系我们"),
      path: "/Contact",
      power: 1,
      children: [
        // { title: i18n.t("公司地址"), path: "/CallUs", children: [], tag: '' },
        // { title: i18n.t("联系方式"), path: "/CallUs", children: [], tag: '' },
      ],
    },
  ];
  return list
}