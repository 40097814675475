import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
const msg = {
    ...zhLocale,
    test:'中文',
    首页:'首页',
    关于我们:'关于',
    产品中心:'产业布局',
    新闻资讯:'资讯',
    联系我们:'联系',
    企业简介:'企业简介',
    核心优势:'核心优势',
    生产实力:'生产实力',
    荣誉资质:'荣誉资质',
    产品证书:'产品证书',
    企业新闻:'企业新闻',
    行业动态:'行业动态',
    公司地址:'公司地址',
    联系方式:'联系方式',
    产品系列:'产品系列',
    更多:'更多',
    详细内容:'详细内容',
    了解详情:'了解详情',
    了解更多:'了解更多',
    查看详情:'查看详情',
    详情:'详情',
    占地面积:'占地面积',
    年:'年',
    公司成立于:'公司成立于',
    名员工:'名员工',
    更多新闻:'更多新闻',
    出口国家:'出口国家',
    产品细节:'产品细节',
    产品参数:'产品参数',
    产品视频:'产品视频',
    文档说明书:'文档说明书',
    地址:'地 址',
    电话:'外销部',
    内销部:'内销部',
    传真:'传 真',
    邮箱:'E-mail',
    网址:'网址',
    返回:'返回',
    上一条:'上一条',
    下一条:'下一条',
    点击下载:'点击下载',
    下载:'下载',
    服务热线:'24小时服务热线',
    查看全部:'查看全部',
    版权所有:'恒河集团有限公司版权所有',
    观看产品视频:'观看产品视频',
    搜索:'请输入关键字',
    搜索产品:'搜索产品',
    装箱清单:'装箱清单',
    产业布局:'产业布局',
    新闻及活动:'新闻及活动',
    集团概况:'集团概况',
    组织架构:'组织架构',
    企业文化:'企业文化',
    发展历程:'发展历程',
    公司:'浙江恒河实业集团有限公司',
    上一篇:'上一篇',
    下一篇:'下一篇',
    化工版块:'化工版块',
    投资版块:'投资版块',
    置业版块:'置业版块',
    跳转官网:'跳转官网',
    投资简介:'投资简介',
    投资项目:'投资项目',
    月:'月',
    恒河成员公司:'恒河成员公司',
    恒河集团架构:'恒河集团架构',
    集团新闻:'集团新闻',
    政策法规:'政策法规',
    关闭:'关闭',
    子公司网站:'子公司网站',
    股权投资:'股权投资',
    实业投资:'实业投资',
    加载更多:'加载更多',
    作品:'作品',
}
export default msg;