<template>
  <div>
    <home-banner v-if="!isMiniWindows"/>
    <home-banner-swiper v-else/>
    <div
      class="bg_div padding"
      :style="{
        'background-image': `url(${encodeURI(
          changeImgIndex == 0
            ? homemsg.bgImg1
            : changeImgIndex == 1
            ? homemsg.bgImg2
            : changeImgIndex == 2
            ? homemsg.bgImg3
            : changeImgIndex == 3
            ? homemsg.bgImg4
            : imgs[0].img
        )})`,
      }"
    >
      <div class="mid_div">
        <div class="mid_left_div">
          <p class="p1" v-html="homemsg.title"></p>
          <div class="mid_left_sub_div">
            <div>
              <p class="p2" v-html="homemsg.midTitle"></p>
              <p class="p3" v-html="homemsg.bottomTitle"></p>
            </div>
            <p class="more margin_left" @click="navto('/About')">了解更多</p>
          </div>
        </div>
        <div class="mid_right_div" v-if="!isMiniWindows">
          <div class="mid_right_sub_div">
            <video
              v-if="homemsg.bottomImg.includes('.mp4')"
              muted
              autoplay
              loop="loop"
            >
              <source :src="homemsg.bottomImg" type="video/mp4" />
            </video>
            <img
              v-else
              :src="homemsg.bottomImg"
              @mouseenter="changeImg(0)"
              @mouseleave="resetImg()"
            />
            <video
              v-if="homemsg.midImg.includes('.mp4')"
              muted
              autoplay
              loop="loop"
            >
              <source :src="homemsg.midImg" type="video/mp4" />
            </video>
            <img
              v-else
              :src="homemsg.midImg"
              @mouseenter="changeImg(1)"
              @mouseleave="resetImg()"
            />
          </div>
          <div class="mid_right_sub_div">
            <video
              v-if="homemsg.content.includes('.mp4')"
              muted
              autoplay
              loop="loop"
            >
              <source :src="homemsg.content" type="video/mp4" />
            </video>
            <img
              v-else
              :src="homemsg.content"
              @mouseenter="changeImg(2)"
              @mouseleave="resetImg()"
            />
            <video
              v-if="homemsg.bottomMsg.includes('.mp4')"
              muted
              autoplay
              loop="loop"
            >
              <source :src="homemsg.bottomMsg" type="video/mp4" />
            </video>
            <img
              v-else
              :src="homemsg.bottomMsg"
              @mouseenter="changeImg(3)"
              @mouseleave="resetImg()"
            />
          </div>
        </div>
        <div v-else class="mid_right_carousel_div">
          <div class="swiper-container mySwiper mySwiper2 mid_carousel" id="mySwiper2">
        <div class="swiper-wrapper">
          <!-- Slides -->
          <div class="swiper-slide">
            <div class="mid_banner_div">
                  <video
                    v-if="homemsg.bottomImg.includes('.mp4')"
                    muted
                    autoplay
                    loop="loop"
                  >
                    <source :src="homemsg.bottomImg" type="video/mp4" />
                  </video>
                  <img :src="homemsg.bottomImg" />
                </div>
          </div>
          <div class="swiper-slide">
            <div class="mid_banner_div">
                  <video
                    v-if="homemsg.midImg.includes('.mp4')"
                    muted
                    autoplay
                    loop="loop"
                  >
                    <source :src="homemsg.midImg" type="video/mp4" />
                  </video>
                  <img :src="homemsg.midImg" />
                </div>
          </div>
          <div class="swiper-slide">
            <div class="mid_banner_div">
                  <video
                    v-if="homemsg.content.includes('.mp4')"
                    muted
                    autoplay
                    loop="loop"
                  >
                    <source :src="homemsg.content" type="video/mp4" />
                  </video>
                  <img :src="homemsg.content" />
                </div>
          </div>
          <div class="swiper-slide">
            <div class="mid_banner_div">
                  <video
                    v-if="homemsg.bottomMsg.includes('.mp4')"
                    muted
                    autoplay
                    loop="loop"
                  >
                    <source :src="homemsg.bottomMsg" type="video/mp4" />
                  </video>
                  <img :src="homemsg.bottomMsg" />
                </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
        </div>
      </div>
    </div>
    <div class="width_div padding_top_100 padding_bottom_120">
      <p class="title_arrow" @click="navto('/Works')">我们的作品</p>
      <cases-list v-if="!isMiniWindows" pageSize="3"></cases-list>
      <phone-cases-banner v-else pageSize="3"></phone-cases-banner>
      <p class="more gray margin_top margin_center" @click="navto('/Works')">
        查看更多
      </p>
    </div>
    <div class="business_div gray_bg">
      <div class="width_div between flex padding_top_120 padding_bottom_155">
        <p>我们的三大核心业务</p>
        <business-view></business-view>
      </div>
    </div>
    <div
      class="width_div relative between flex padding_top_120 padding_bottom_50"
    >
      <p>我们服务过的客户</p>
      <customers-view></customers-view>
    </div>
  </div>
</template>

<script>
import Swiper, { Pagination } from "swiper";
Swiper.use(Pagination);
import countTo from "vue-count-to";
import * as API from "@/api/index";
import HomeBanner from "./view/HomeBanner.vue";
import HomeBannerSwiper from "./view/HomeBannerSwiper.vue";
import PhoneCasesBanner from "./view/PhoneCasesBanner.vue";
import HomeProductBanner from "./view/HomeProductBanner.vue";
import HomeProductBannerPhone from "./view/HomeProductBannerPhone.vue";
import ArcBg from "./view/ArcBg.vue";
import AccordionView from "./view/AccordionView.vue";
import AboutSwiper2 from "./view/AboutSwiper2.vue";
import AboutSwiper5 from "./view/AboutSwiper5.vue";
import BusinessView from "./view/BusinessView.vue";
import CustomersView from "./view/CustomersView.vue";
import CasesList from "./view/CasesList.vue";
export default {
  data() {
    return {
      oldScrollTop: 0,
      numScrollStart: false,
      company: "",
      news: [],
      homemsg: [{ title: "", titleEn: "", midTitle: "", midTitleEn: "",bottomImg:'',midImg:'',content:'',bottomMsg:'' }],
      imgs: [{ img: "" }],
      types: [{}, {}, {}, {}, {}, {}, {}],
      isMiniWindows: false,
      clientWidth: 0,
      changeImgIndex: -1,
      swiper:Object,
    };
  },
  components: {
    HomeBanner,
    HomeBannerSwiper,
    HomeProductBanner,
    PhoneCasesBanner,
    HomeProductBannerPhone,
    ArcBg,
    AccordionView,
    AboutSwiper2,
    countTo,
    AboutSwiper5,
    BusinessView,
    CustomersView,
    CasesList,
  },
  watch: {
    clientWidth(newVal, oldVal) {
      // console.log(newVal); //浏览器窗口变化时，打印宽度。
      if (newVal < 1000) {
        this.isMiniWindows = true;
      } else {
        this.isMiniWindows = false;
      }
    },
    $route(to, from) {
      if (to.path == "/") {
        window.scrollTo(0, 0);
      }
    },
  },
  mounted() {
    this.Init();
    window.scrollTo(0, 0);
    
  },

  methods: {
    navto(path, param) {
      if (param) {
        this.$router.push({
          path: `${path}`,
          query: { param: param },
        });
      } else {
        this.$router.push(path);
      }
    },
    Init() {
      this.getHomemsg();
      this.getImg();
      this.getIsMini();
      // this.getTypeList();
      // 监听页面滚动事件
      window.addEventListener("scroll", this.scrolling);
      window.addEventListener("resize", this.getIsMini);
      const ele = document.querySelector("#home_about_box");
      console.log("ele==", ele);
    },
    getIsMini(){
      let that = this;
    var clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    that.clientWidth = clientWidth;
    window.onresize = () => {
      return (() => {
        var clientWidth =
          document.documentElement.clientWidth || document.body.clientWidth;
        that.clientWidth = clientWidth;
      })();
    };
    },
    getImg() {
      var p = { title: "首页背景图" };
      API.getImgs(p)
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.imgs = res.list;
          }
        })
        .catch(() => {});
    },
    getHomemsg() {
      API.getHomemsg()
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.homemsg = res.page.list[0];
            this.initSwiper();
          }
        })
        .catch(() => {});
    },
    initSwiper() {
      let that = this;
      this.$nextTick(() => {
        that.swiper = new Swiper(".mySwiper2", {
          slidesPerView: 1,
          centeredSlides: true,
          loop: true,
          // autoplay: {
          //   disableOnInteraction: false,
          //   delay: 4000,
          // },
          pagination: {
            el: ".swiper-pagination",
          },
          on: {
            activeIndexChange: function () {
              // console.log("改变了，activeIndex为" + this.activeIndex);
            },
            slideChange: function () {
              // console.log("slideChange==" + this.activeIndex);
              // console.log("slideChange real==" + this.realIndex);
              that.setActiveIndex(this.realIndex);
            },
          },
        });
      }, 600);
    },
    //判断介绍部分是否出现，开始数字动画
    scrolling() {
      // 滚动条距文档顶部的距离（做兼容处理）===》不懂的可以结合画图理清逻辑
      let scrollTop =
        window.scrollY ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop;
      // console.log("oldScrollTop==" + this.oldScrollTop);
      var clientWidth =
        document.documentElement.clientWidth || document.body.clientWidth;
      if (clientWidth < 1600) {
        if (this.oldScrollTop > 600) {
          this.handleScroll();
        }
      } else if (clientWidth < 1700) {
        if (this.oldScrollTop > 1200) {
          this.handleScroll();
        }
      } else {
        if (this.oldScrollTop > 1800) {
          this.handleScroll();
        }
      }
    },
    handleScroll() {
      if (!this.numScrollStart) {
        // this.$refs.mynum1.start();
        // this.$refs.mynum2.start();
        // this.$refs.mynum3.start();
      }
      this.numScrollStart = true;
    },
    getTypeList() {
      API.getProductType()
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.types = res.data;
          }
        })
        .catch(() => {});
    },
    changeImg(index) {
      this.changeImgIndex = index;
    },
    resetImg() {
      // this.changeImgIndex = -1;
    },
    //设置滑动切换轮播图
    swiperleft: function (index) {
      //上一页
      this.$refs.midcarousel.prev();
      //设置幻灯片的索引
      this.$refs.midcarousel.setActiveItem(index - 1);
    },
    swiperright: function (index) {
      //下一页
      this.$refs.midcarousel.next();
      this.$refs.midcarousel.setActiveItem(index + 1);
    },
    setActiveIndex(nowindex, oldindex) {
      // console.log("now==", nowindex);
      // console.log("old==", oldindex);
      this.changeImg(nowindex);
    },
  },
  created() {
    // window.addEventListener("onmessageWS", this.addAnimatedIn);
  },
  destroyed() {
    // 移除事件监听
    window.removeEventListener("scroll", this.scrolling);
  },
  updated() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.mid_div {
  display: flex;
  margin: auto;
  max-width: 140rem;
  .mid_left_div {
    flex: 1;
    display: flex;
    .mid_left_sub_div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .p1 {
      color: black;
      text-align: start;
    }
    .p2 {
      @extend .p1;
      letter-spacing: -1px;
      font-size: 4.8rem;
      margin-left: 6.5rem;
      line-height: 5.8rem;
    }
    .p3 {
      @extend .p2;
      margin-top: 3rem;
      font-size: 3.2rem;
      line-height: 4rem;
    }
  }
  .mid_right_div {
    .mid_right_sub_div {
      display: flex;
      box-sizing: border-box;
      &:first-child {
        margin-bottom: 2.5rem;
      }
      img {
        z-index: 10;
        border-radius: 2rem;
        transition: all 0.5s;
        width: 30rem;
        height: 22.5rem;
        &:last-child {
          margin-left: 2.5rem;
        }
        &:hover {
          box-shadow: #00000030 0 0 16px 1px;
          z-index: 20;
          transition: all 0.3s;
          transform: scale(1.24);
        }
      }
    }
  }
}

.business_div {
  display: flex;
  p {
    color: black;
  }
  &.gray_bg {
    background: linear-gradient(157deg, #f4f4f4 0%, #e8e8e8 100%);
  }
}

@media screen and (max-width: 1000px) {
  .bg_div.padding {
    padding: 5.7rem 0;
  }
  .mid_div {
    flex-direction: column;
    .mid_left_div {
      flex-direction: column;
      // margin-left: 4.2rem;
      .p1 {
        padding-left: 4.2rem;
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
        // line-height: 2.7rem;
      }
      .p2 {
        font-size: 2.3rem;
        line-height: 3.1rem;
        margin-left: 0;
      }
      .p3 {
        margin-top: 2.3rem;
        margin-bottom: 0;
        font-size: 1.7rem;
        line-height: 2.3rem;
      }
    }
  }
  .more.margin_left {
    margin: 2.5rem auto 3rem;
  }
  .mid_right_carousel_div {
    height: 30rem;
    .wrapper {
      height: 25.8rem;
    }
    .mid_carousel {
      height: 100%;
    }
    .mid_banner_div {
      // height: 100%;
      height: 25.8rem;
      img {
        height: 100%;
        border-radius: 2rem;
      }
    }
    /deep/ .swiper-pagination-bullet {
      cursor: $point_1, pointer !important;
      width: 1rem;
      border-radius: 50%;
      height: 1rem;
      margin: 0 5px;
      opacity: 1;
      background-color: #666666b3;
      overflow: hidden;
      // transition: all 0.5s;
    }

    /deep/ .swiper-pagination-bullet-active {
      background-color: #666666ff;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      // transition: all 0.5s;
      // width: 22.5rem;
    }
  }
  .title_arrow {
    letter-spacing: 0px;
    font-size: 1.7rem;
    margin-left: 2.7rem;
    line-height: 2.3rem;
    &::after {
      background: none;
    }
  }
  .width_div {
    p {
      text-align: start;
      font-size: 1.7rem;
      margin-left: 2.7rem;
    }
    &.flex {
      flex-direction: column;
    }
    &.padding_bottom_120 {
      padding-bottom: 5.7rem;
    }
    &.padding_bottom_155 {
      padding-bottom: 5.7rem;
    }
    &.padding_top_100 {
      padding-top: 5.7rem;
    }
    &.padding_top_120 {
      padding-top: 5.7rem;
    }
  }
  .business_div {
    p {
      text-align: start;
      font-size: 1.7rem;
      margin-left: 2.7rem;
    }
  }
}
</style>
