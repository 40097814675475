import request from '@/axios/request'

export function getAbout(params) {
    return request({
        url: '/companyinfo/list',
        method: 'get',
        params: params
    })
}

export function createMsg (data) {
    return request({
        url: '/msg/createMsg',
        method: 'post',
        data: data
    })
}
export function getHomeBanner(params){
    return request({
        url: '/homebanner/list',
        method: 'get',
        params: params
    })
}
export function getAllList(params){
    return request({
        url: '/all/list',
        method: 'get',
        params: params
    })
}
export function getBusinessBanner(params){
    return request({
        url: '/businessbanner/list',
        method: 'get',
        params: params
    })
}
export function getCallus(params){
    return request({
        url: '/callus/list',
        method: 'get',
        params: params
    })
}

export function saveArchitecture(params){
    return request({
        url: '/architecture/save',
        method: 'post',
        data: params
    })
}

//化工板块
export function getChemicalindustry(params){
    return request({
        url: '/chemicalindustry/list',
        method: 'get',
        params: params
    })
}
export function getChemicalindustryDetail(id){
    return request({
        url: `/chemicalindustry/info/${id}`,
        method: 'get',
    })
}
//企业文化
export function getCorporateculture(params){
    return request({
        url: '/corporateculture/list',
        method: 'get',
        params: params
    })
}
export function getHistory(params){
    return request({
        url: '/history/list',
        method: 'get',
        params: params
    })
}
export function getHomemsg(params){
    return request({
        url: '/homemsg/list',
        method: 'get',
        params: params
    })
}
//投资板块
export function getInvestment(params){
    return request({
        url: '/investment/list',
        method: 'get',
        params: params
    })
}
export function getInvestmentDetail(id){
    return request({
        url: `/investment/info/${id}`,
        method: 'get',
    })
}
export function getImgs(params){
    return request({
        url: '/imgs/list',
        method: 'get',
        params: params
    })
}
export function getCases(params){
    return request({
        url: '/news/list',
        method: 'get',
        params: params
    })
}
export function getCasesDetail(id){
    return request({
        url: `/news/info/${id}`,
        method: 'get',
    })
}
//成员公司
export function getMember(params){
    return request({
        url: '/member/list',
        method: 'get',
        params: params
    })
}
//集团概况
export function getOverview(){
    return request({
        url: '/overview/list',
        method: 'get',
    })
}
//置业板块
export function getProperty(params){
    return request({
        url: '/property/list',
        method: 'get',
        params: params
    })
}
export function getNewsDetail(id){
    return request({
        url: `/property/info/${id}`,
        method: 'get',
    })
}