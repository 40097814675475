<template>
  <div>
    <template v-for="(item, index) in data">
      <!-- 因为有子集和无子集渲染html标签不一样，所以要分为两种情况
             情况一：有子集的情况：                         -->
      <el-submenu
        :key="index"
        :index="item.title"
        v-if="item.children && item.children.length > 0 && item.power == 1"
      >
        <template slot="title">
          <!-- <i :class="item.icon"></i> -->
          <span :class="item.id ? 'subtitle' : ''">{{
            item.titleEn
              ? $i18n.locale == "zh"
                ? item.title
                : item.titleEn
              : item.title
          }}</span>
        </template>
        <myitem :data="item.children"></myitem>
      </el-submenu>
      <!-- 情况二：没子集的情况： -->
      <el-menu-item
        v-else
        :index="item.title"
        :key="item.title"
        @click="navto(item.path, item.tag)"
      >
        <!-- <i :class="item.icon"></i> -->
        <span slot="title">{{
          item.titleEn
            ? $i18n.locale == "zh"
              ? item.title
              : item.titleEn
            : item.title
        }}</span>
      </el-menu-item>
    </template>
  </div>
</template>
  
  <script>
export default {
  name: "myitem",
  props: {
    data: {
      type: Array,
      default: [],
    },
  },
  methods: {
    navto(path, tag) {
      if (tag) {
        this.$router.push({
          path: path,
          query: { param: tag },
        });
      } else {
        this.$router.push(path);
      }
    },
  },
  // 注意： 在template标签上使用v-for，:key="index"不能写在template标签上，因为其标签不会被渲染，会引起循环错误
};
</script>
<style lang="scss" scoped>
// /deep/.el-icon-arrow-down:before {content: "\E790" !important; }
/deep/.el-icon-arrow-down::before {
  content: none;
  transform: none;
}
/deep/.el-submenu.is-opened > .el-submenu__title .el-submenu__icon-arrow {
  background-image: url("../../../static/img/silde_close.png");
}
/deep/.el-submenu__title {
  display: flex;
  color: $color2C;
  font-family: medium;
  font-size: 1.8rem;
  align-items: center;
  .el-submenu__icon-arrow {
    background-repeat: no-repeat;
    background-size: contain;
    width: 3rem;
    height: 3rem;
    background-image: url("../../../static/img/silde_open.png");
    transform: none !important;
    top: unset;
  }
  //   .el-icon-arrow-down{
  //     transform: none;
  //     background-image: url("../../../static/img/silde_close.png");
  //   }
  //   i {
  //     background-repeat: no-repeat;
  //     background-size: contain;
  //     width: 2rem;
  //     height: 2rem;
  //     background-image: url("../../../static/img/silde_open.png");
  //     transform: none !important;
  //   }
}
.subtitle {
  color: $textGaryColor;
  font-family: light;
  font-size: 1.6rem;
}
//修改item背景色
/deep/.el-submenu {
  background-color: transparent;
}
/deep/ .el-menu-item {
  color: $color2C;
  font-family: medium;
  font-size: 1.8rem;
  text-align: start;
  &.is-active {
    color: $color2C;
  }
}
/deep/.el-submenu .el-menu-item {
  display: flex;
  color: $color2C;
  font-family: regular;
  font-size: 1.6rem;
  &.is-active {
    color: $textRedColor;
  }
}
</style>