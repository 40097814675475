import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/Home'
import Index from '@/components/Index'
import About from '@/components/About'
import Cases from '@/components/Cases'
import News from '@/components/News'
import NewsDetail from '@/components/NewsDetail'
import CasesDetail from '@/components/CasesDetail'
import CallUs from '@/components/CallUs'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '有限公司',
    component: Index,
    children: [{
      path: '/',
      component: Home,
      name: '',
      meta: { title: '有限公司', titleEn: 'Zhejiang ' },

    },
    {
      path: '/Works',
      component: Cases,
      name: '',
      meta: { title: '有限公司', titleEn: 'Zhejiang ' },
    },
    {
      path: '/WorksDetail/:id',
      component: CasesDetail,
      name: '',
      meta: { title: '有限公司', titleEn: 'Zhejiang ' },
    },
    {
      path: '/About',
      component: About,
      name: '',
      meta: { title: '有限公司', titleEn: 'Zhejiang ' },
    },
    {
      path: '/News',
      component: News,
      name: '',
      meta: { title: '有限公司', titleEn: 'Zhejiang ' },
    },
    {
      path: '/NewsDetail/:id',
      component: NewsDetail,
      name: '',
      meta: { title: '有限公司', titleEn: 'Zhejiang ' },
    },
    {
      path: '/Contact',
      component: CallUs,
      name: '',
      meta: { title: '有限公司', titleEn: 'Zhejiang ' },
    },
    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
