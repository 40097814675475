import { render, staticRenderFns } from "./CasesList.vue?vue&type=template&id=639b6f54&scoped=true&"
import script from "./CasesList.vue?vue&type=script&lang=js&"
export * from "./CasesList.vue?vue&type=script&lang=js&"
import style0 from "./CasesList.vue?vue&type=style&index=0&id=639b6f54&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "639b6f54",
  null
  
)

export default component.exports