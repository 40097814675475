<template>
  <div class="width_div">
    <div class="detail_div">
      <p class="detail_title">
        {{ $i18n.locale == "zh" ? Detail.title : Detail.titleEn }}
      </p>
      <p class="detail_time">
        <!-- <span class="detail_type">{{
          $i18n.locale == "zh" ? Detail.typeName : Detail.typeNameEn
        }}</span> -->
        {{ Detail.time }}
      </p>
      <p
        class="detail_content"
        v-html="$i18n.locale == 'zh' ? Detail.content : Detail.contentEn"
      ></p>
    </div>
    <div class="bottom_div">
      <el-row :gutter="25" type="flex">
        <el-col :md="12" :xs="12">
          <p class="info">上一篇</p>
          <div
            class="news_item_div"
            @click="pushTo('/NewsDetail/id=' + PrevDetail.id)"
          >
            <div class="img_div">
              <video
                v-if="PrevDetail.cover.includes('.mp4')"
                muted
                autoplay
                loop="loop"
              >
                <source :src="PrevDetail.cover" type="video/mp4" />
              </video>
              <img v-else :src="PrevDetail.cover" />
              <!-- <img :src="PrevDetail.cover" /> -->
              <div class="cover_div"></div>
            </div>
            <div class="item_div">
              <p class="p1">{{ PrevDetail.time }}</p>
              <p class="p2">{{ PrevDetail.title }}</p>
            </div>
          </div>
        </el-col>
        <el-col :md="12" :xs="12">
          <p class="info">下一篇</p>
          <div
            class="news_item_div"
            @click="pushTo('/NewsDetail/id=' + NextDetail.id)"
          >
            <div class="img_div">
              <video
                v-if="NextDetail.cover.includes('.mp4')"
                muted
                autoplay
                loop="loop"
              >
                <source :src="NextDetail.cover" type="video/mp4" />
              </video>
              <img v-else :src="NextDetail.cover" />
              <!-- <img :src="NextDetail.cover" /> -->
              <div class="cover_div"></div>
            </div>
            <div class="item_div">
              <p class="p1">{{ NextDetail.time }}</p>
              <p class="p2">{{ NextDetail.title }}</p>
            </div>
          </div>
        </el-col>
      </el-row>
      <p class="more margin_top margin_center" @click="pushTo('/News')">
        查看更多
      </p>
    </div>
  </div>
</template>
  <script>
import * as API from "@/api/index";
export default {
  components: {},
  data() {
    return {
      Detail: "",
      NextDetail: {cover:''},
      PrevDetail: {cover:''},
      list: "",
      hasPrev: false,
      hasNext: false,
    };
  },
  watch: {
    $route(to, from) {
      //   console.log(to.path);
      if (to.path.indexOf("/NewsDetail") != -1) {
        window.scrollTo(0, 0);
        this.getList();
      }
      //   var param = this.$route.query.param;
      //   this.path = param;
      //   console.log("param:" + param);
      //   this.navtoPartPath(this.path);
    },
  },
  created() {},
  computed: {},
  mounted() {
    window.scrollTo(0, 0);
    this.getList();
  },
  methods: {
    getList() {
      var data = {
        page: 1,
        limit: 999,
      };
      API.getProperty(data)
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.list = res.page.list;
            this.getDetail();
            this.getNextPrevDetail();
          }
        })
        .catch(() => {});
    },
    getNextPrevDetail() {
      let news_id = window.location.href.split("id=")[1];
      for (var i = 0; i < this.list.length; i++) {
        var item = this.list[i];
        if (item.id == news_id) {
          if (i == this.list.length - 1) {
            this.NextDetail = this.list[0];
            this.PrevDetail = this.list[i - 1];
          } else if (i == 0) {
            this.NextDetail = this.list[i + 1];
            this.PrevDetail = this.list[this.list.length - 1];
          } else {
            this.NextDetail = this.list[i + 1];
            this.PrevDetail = this.list[i - 1];
          }
          break;
        }
      }
    },
    getDetail() {
      let news_id = window.location.href.split("id=")[1];
      API.getNewsDetail(news_id)
        .then((res) => {
          console.log(res);
          var status = res.code;
          if (status === 0) {
            this.Detail = res.news;
          }

          for (var i = 0; i < this.list.length; i++) {
            var item = this.list[i];
            if (item.id == news_id) {
              if (i > 0) {
                this.hasPrev = true;
              } else {
                this.hasPrev = false;
              }
              if (i < this.list.length - 1) {
                this.hasNext = true;
              } else {
                this.hasNext = false;
              }
              break;
            }
          }
        })
        .catch(() => {});
    },
    goback() {
      // this.$router.back();
      this.$router.push("/News");
    },
    pushTo(path) {
      this.$router.push(path);
    },
  },
};
</script>
  <style lang="scss" scoped>
.detail_div {
  // padding:20rem 45rem;
  width: 66%;
  margin-top: 28rem;
  margin-bottom: 10rem;
  max-width: 92.5rem;
  p {
    text-align: start;
  }
  .detail_title {
    font-size: 3.6rem;
    letter-spacing: -1px;
    line-height: 4.3rem;
  }
  .detail_type {
    font-size: 1.8rem;
    color: $textRedColor;
    background: $colorF6F6F7;
    padding: 2px 1.4rem;
    margin-right: 1.3rem;
  }
  .detail_time {
    margin-top: 1rem;
    font-size: 2.4rem;
    line-height: 2.9rem;
    color: $color999;
  }
  /deep/ .detail_content {
    margin-top: 10rem;
    font-size: 2.4rem;
    line-height: 4rem;
    p {
      font-size: 2.4rem;
      line-height: 4rem;
    }
    img {
      max-width: 100%;
    }
    video{
      max-width: 100%;
    }
  }
}
.bottom_div {
  width: 66%;
  margin-bottom: 10rem;
  .info {
    font-size: 2.2rem;
    color: $color999;
    text-align: start;
  }
}

@media (max-width: 1000px) {
  .width_div {
    width: 100%;
    max-width: 100%;
  }
  .detail_div {
    margin: 11rem auto 5.7rem;
    width: 77.6%;
    max-width: 29.1rem;
    .detail_title {
      font-size: 2.5rem;
      line-height: 3.1rem;
    }
    .detail_type {
      font-size: 1.7rem;
    }
    .detail_time {
      font-size: 1.7rem;
      margin: 1.5rem 0 3.8rem;
    }
    /deep/ .detail_content {
      margin-top: 0;
      font-size: 1.1rem;
      line-height: 1.9rem;
      p {
        font-size: 1.1rem;
        line-height: 1.9rem;
      }
    }
  }
  .bottom_div {
    background: #f3f3f3;
    margin: 0 auto;
    padding-top: 3.8rem;
    padding-bottom: 16.2rem;
    width: 100%;
    padding-left: 18px;
    padding-right: 18px;
    box-sizing: border-box;
    overflow: hidden;
    // justify-content: space-evenly;
    /deep/ .el-col {
      padding: 0 !important;
      margin: 0 6px;
      background: white;
    }
    .info {
      color: black;
      font-size: 1.3rem;
      background: #f3f3f3;
      padding-left: 2.7rem;
      padding-bottom: 1.5rem;
    }
    .right_div {
      display: none;
    }
    p {
      &:nth-child(2) {
        margin-left: 0.6rem;
        margin-right: 0.6rem;
      }
    }
  }
  .news_item_div {
    margin-top: 0;
    .img_div {
      height: 9.3rem;
    }
    .item_div {
      background: white;
      padding: 1.9rem 2.7rem 1.5rem;
    }
    .p1 {
      font-size: 1.1rem;
      margin: 0;
    }
    .p2 {
      font-size: 1.1rem;
      line-height: 1.7rem;
      margin: 8px 0 0 !important;
    }
  }
  .news_detail_more_touch_box {
    min-width: unset;
    font-size: 1.3rem;
    padding: 0.6rem 3rem;
  }
  .news_detail_more_touch_box_left {
    &::before {
      width: 1rem;
      height: 1rem;
      margin: auto 0.1rem auto 0;
    }
  }
  .news_detail_more_touch_box_right {
    &::after {
      width: 1rem;
      height: 1rem;
      margin: auto 0 auto 0.1rem;
    }
  }
}
</style>