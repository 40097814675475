<template>
  <div class="father_div" :class="isOpen ? 'open' : ''">
    <el-row :gutter="60">
      <el-col :md="6" :xs="12" :sm="12" v-for="(item, i) in list" :key="i">
        <div
          class="logo"
          @mouseenter="enter(i)"
          @mouseleave="leave()"
          
        >
          <img
            class="logo1"
            :class="activeIndex!=i?'show':'hide'"
            :src=" item.icon"
            />
            <img
            :class="activeIndex==i?'show':'hide'"
            class="logo2"
            :src=" item.icon2 "
          />
        </div>
      </el-col>
    </el-row>
    <div class="arrow_div" :class="isOpen ? 'isopen' : ''">
      <div class="img_div" @click="open()">
        <img src="../../../static/img/arrow-down.svg" />
      </div>
    </div>
  </div>
</template>
<script>
import * as API from "@/api/index";
export default {
  data() {
    return {
      list: [],
      isOpen: false,
      activeIndex: -1,
    };
  },
  mounted() {
    this.getCorporateculture();
  },
  methods: {
    getCorporateculture() {
      var p = { page: 1, limit: 100 };
      API.getCorporateculture(p)
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.list = res.page.list;
          }
        })
        .catch(() => {});
    },
    open() {
      this.isOpen = !this.isOpen;
    },
    enter(i) {
      this.activeIndex = i;
    },
    leave() {
      this.activeIndex = -1;
    },
  },
};
</script>
<style lang="scss" scoped>
.father_div {
  width: 113rem;
  height: 107rem;
  overflow: hidden;
  //   position: relative;
  &.open {
    height: auto;
  }
}
.logo {
  position: relative;
  width: 24rem;
  height: 18rem;
  // object-fit: scale-down;
  // background: $colorF4;
  margin-bottom: 6rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-origin: border-box;
  transition: all 0.5s ease-in-out;
  &:hover {
    transition: all 0.5s ease-in-out;
    // background: red;
    background-repeat: no-repeat;
    background-size: contain;
    background-origin: border-box;
  }
  .logo1{
    height:100%;
    width:100%;
    object-fit: contain;
    &.show{
      transition: all 0.5s;
      opacity: 1;

    }
    &.hide{
      transition: all 0.5s;
      opacity: 0;
    }
  }
  .logo2{
    position: absolute;
    top: 0;
    left: 0;
    height:100%;
    width:100%;
    object-fit: contain;
    &.show{
      transition: all 0.5s;
      opacity: 1;

    }
    &.hide{
      transition: all 0.5s;
      opacity: 0;
    }
  }
}
.arrow_div {
  background: linear-gradient(
    360deg,
    #ffffff 50%,
    rgba(255, 255, 255, 0.98) 56%,
    rgba(255, 255, 255, 0.93) 62%,
    rgba(255, 255, 255, 0.85) 69%,
    rgba(255, 255, 255, 0.74) 75%,
    rgba(255, 255, 255, 0.59) 82%,
    rgba(255, 255, 255, 0.41) 88%,
    rgba(255, 255, 255, 0.2) 94%,
    rgba(255, 255, 255, 0) 100%
  );
  height: 42rem;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  .img_div {
    width: 20%;
    height: 30%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 2.8rem;
      height: 2.8rem;
      margin: auto;
    }
  }
  &.isopen {
    transition: all 0.5s;
    height: 0;
    .img_div {
      opacity: 0;
    }
  }
}
@media screen and (max-width: 1000px) {
  .father_div {
    width: 100%;
    height: 50rem;
    padding: 0 2.7rem;
    box-sizing: border-box;
  }
  /deep/ .el-row {
    margin: 0 !important;
  }
  /deep/ .el-col {
    padding: 0 !important;
  }
  .logo {
    // background: #f3f3f3;
    width: 13.8rem;
    height: 10.4rem;
    margin-bottom: 1.5rem;
  }
  .arrow_div {
    height: 20rem;
    .img_div {
      img {
        height: 1rem;
        width: 1rem;
      }
    }
    &.isopen {
      transition: all 0.5s;
      height: 0;
      .img_div {
        opacity: 0;
      }
    }
  }
}
</style>