<template>
  <div class="father_div">
    <el-row :gutter="25">
      <el-col :md="8" v-for="(item, i) in news" :key="i">
        <div
          class="cases_item_div bg_div"
          @click="pushTo('/WorksDetail/id=' + item.id)"
        >
          <video v-if="item.cover.includes('.mp4')" muted autoplay loop="loop">
            <source :src="item.cover" type="video/mp4" />
          </video>
          <img v-else :src="item.cover" />
          <div class="item_div">
            <p class="p1">{{ item.title }}</p>
            <p class="p2">{{ item.simple }}</p>
            <p class="p3">{{ item.typeName }}</p>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import * as API from "@/api/index";
export default {
  props: ["pageSize", "listID"],
  watch: {
    listID(newdata, olddata) {
      // console.log("watch newdata==", newdata);
      // console.log("watch olddata==", olddata);
      this.getCases();
    },
  },
  data() {
    return {
      news: [],
      isOpen: false,
    };
  },
  mounted() {
    if (!this.listID) {
      this.getCases();
    }
  },
  methods: {
    getCases() {
      this.news.length = 0;
      var data = {
        page: 1,
        limit: this.pageSize,
      };
      API.getCases(data)
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            if (this.listID) {
              for (var i = 0; i < this.listID.length; i++) {
                var id = this.listID[i];
                // console.log("i==", i);
                for (var j = 0; j < res.page.list.length; j++) {
                  // console.log("j==", j);
                  var item = res.page.list[j];
                  if (item.id == id) {
                    // console.log("push==", id);
                    this.news.push(item);
                    break;
                  }
                }
              }
            } else {
              this.news = res.page.list;
            }
          }
        })
        .catch(() => {});
    },
    pushTo(path) {
      this.$router.push(path);
    },
  },
};
</script>
<style lang="scss" scoped>
.father_div {
  margin-top: 5rem;
}
.cases_item_div {
  cursor: $point_3, pointer;
  margin-top: 2.5rem;
  height: 60rem;
  width: 45rem;
  position: relative;
  // box-sizing: border-box;
  img {
    width: 100%;
    height: 100%;
  }
  video {
    width: 100%;
    height: 100%;
  }
  .item_div {
    transition: all 0.5s;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-sizing: content-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }
  .p1 {
    margin-left: 4.4rem;
    text-align: start;
    color: white;
    font-size: 2.1rem;
  }
  .p2 {
    @extend .p1;
    letter-spacing: -1px;
    transition: all 0.5s;
    font-size: 3.2rem;
    margin-top: 5px;
    margin-bottom: 3.8rem;
  }
  .p3 {
    @extend .p1;
    transition: all 0.5s;
    opacity: 0;
    height: 0;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.8rem;
    overflow: hidden;
  }
  &:hover {
    .item_div {
      transition: all 0.5s;
      background-color: $textBlackColor50;
    }
    .p2 {
      transition: all 0.5s;
      margin-bottom: 0;
    }
    .p3 {
      transition: all 0.5s;
      margin-top: 7px;
      margin-bottom: 4.1rem;
      height: 2.7rem;
      opacity: 1;
    }
  }
}
@media (max-width: 1000px) {
  .father_div {
    margin-top: 1.5rem;
  }
  .cases_item_div {
    margin-top: 2.3rem;
    height: 46rem;
    width: 34.4rem;
  &:hover {
    .item_div {
      transition: all 0.5s;
      background-color: transparent;
    }
    .p2 {
      margin-left: 2.7rem;
      text-align: start;
      color: white;
      letter-spacing: -1px;
      transition: all 0.5s;
      font-size: 2.3rem;
      margin-top: 8px;
      margin-bottom: 1rem;
      line-height: 2.9rem;
    }
    .p3 {
      line-height: 1.2;
      opacity: 1;
      height: auto;
      margin-left: 2.7rem;
      text-align: start;
      color: white;
      transition: all 0.5s;
      font-size: 1.3rem;
      margin-bottom: 3.2rem;
      margin-top: 0;
    }
  }
    .p1 {
      margin-left: 2.7rem;
      text-align: start;
      color: white;
      font-size: 1.5rem;
      line-height: 1.1rem;
    }
    .p2 {
      margin-left: 2.7rem;
      text-align: start;
      color: white;
      letter-spacing: -1px;
      transition: all 0.5s;
      font-size: 2.3rem;
      margin-top: 8px;
      margin-bottom: 1rem;
      line-height: 2.9rem;
    }
    .p3 {
      line-height: 1.2;
      opacity: 1;
      height: auto;
      margin-left: 2.7rem;
      text-align: start;
      color: white;
      transition: all 0.5s;
      font-size: 1.3rem;
      margin-bottom: 3.2rem;
    }
  }
}
</style>