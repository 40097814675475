<template>
  <div>
    <el-carousel
      class="banner"
      indicator-position="outside"
      :autoplay="true"
      trigger="click"
      arrow="never"
      height="100%"
      ref="procarousel"
    >
      <el-carousel-item v-for="(item, index) in List" :key="index">
        <v-touch
          :swipe-options="{ direction: 'horizontal' }"
          v-on:swipeleft="swiperright(index)"
          v-on:swiperight="swiperleft(index)"
          class="wrapper"
        >
          <div class="home_product_box">
            <img :src="item.phoneImg" />
            <div
              class="bottom_div"
              :style="{
                'background-image': `url(${item.icon})`,
              }"
            >
              <p class="type">
                {{ $i18n.locale == "zh" ? item.title : item.titleEn }}
              </p>
              <p
                class="detail_box"
                :class="$i18n.locale == 'zh' ? '' : 'en'"
              >
                {{ $i18n.locale == "zh" ? item.content : item.contentEn }}
              </p>
            </div>
          </div>
        </v-touch>
      </el-carousel-item>
    </el-carousel>
    <!-- <p class="more_about_box" @click="navTo('/Products')">
      {{ $t("查看全部") }}
    </p> -->
  </div>
</template>
<script>
import * as API from "@/api/index";
export default {
  data() {
    return {
      List: [],
    };
  },
  created() {},
  watch: {
    //监听轮播数据变化后再创建实例
    List() {
      this.$nextTick(() => {
        // this.playMultipleBanner();
      });
    },
  },
  computed: {},
  mounted() {
    this.getBannerList();
  },
  destroyed() {},
  methods: {
    getBannerList() {
      API.getBusinessBanner()
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.List = res.page.list;
          }
        })
        .catch(() => {});
    },
    jumpTo(url) {
      window.open(url, "_blank");
    },
    navTo(path, param) {
      if (param) {
        this.$router.push({
          path: `${path}`,
          query: { param: param },
        });
      } else {
        this.$router.push(path);
      }
    },
    //设置滑动切换轮播图
    swiperleft: function (index) {
      //上一页
      this.$refs.procarousel.prev();
      //设置幻灯片的索引
      this.$refs.procarousel.setActiveItem(index - 1);
    },
    swiperright: function (index) {
      //下一页
      this.$refs.procarousel.next();
      this.$refs.procarousel.setActiveItem(index + 1);
    },
  },
};
</script>
<style lang="scss" scoped>
.banner {
  margin-top: 2rem;
}
.home_product_box {
  margin: 0;
  height: 30rem;
  border-radius: unset;
  position: relative;
  img {
    height: 100%;
    width: 100%;
    transform: none;
    border-radius: unset;
    object-fit: cover;
  }
  .type {
    color: white;
    text-align: start;
    font-family: medium;
    font-size: 1.7rem;
  }
  .detail_box {
    margin-top: 5px;
    text-align: justify;
    color: white;
    font-size: 1rem;
    line-height: 1.6rem;
    // border: #1961ac solid 1px !important;
    // background: #1961ac;
    &.en {
      line-height: 1.2rem;
      text-align: start;
    }
  }
}
.home_product_box:hover {
  box-shadow: none;
}
.bottom_div {
  width: 55%;
  padding: 1rem 2rem 2rem;
  background: $textRedColor;
  position: absolute;
  bottom: -1rem;
  left: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  background-origin: content-box;
}
.more_about_box {
  cursor: pointer;
  margin: 5rem auto;
  font-size: 2.2rem;
}
.outside_div {
  height: auto;
  width: 100%;
  z-index: 0;
}
/deep/.el-carousel__container {
  height: 33rem !important;
}
/deep/.el-carousel__button {
  width: 1.8rem;
  height: 1.5px;
  opacity: 1;
  // border-radius: 50%;
  background-color: #d2d2d2;
}

/deep/.el-carousel__indicator.is-active button {
  background-color: $textRedColor;
}

// @media screen and (max-width: 1000px) {

// }
</style>