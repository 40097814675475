<template>
  <div class="father_div">
    <div
      v-for="(item, i) in list"
      :key="i"
      class="item_div"
      :class="i == 0 ? 'color1' : i == 1 ? 'color2' : 'color3'"
    >
      <div class="inside_div">
        <img :src="item.img" />
        <div class="bottom_div">
          <p class="title">{{ item.title }}</p>
          <p class="content" v-html="item.content"></p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as API from "@/api/index";
export default {
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.getOverview();
  },
  methods: {
    getOverview() {
      API.getOverview()
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.list = res.page.list;
          }
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.father_div {
  //   display: flex;
  position: relative;
  width: 113rem;
  height: 48rem;
  margin-top: 7rem;
  transform: translate(0, 50%);
}
.item_div {
  width: 36rem;
  height: 48rem;
  position: absolute;
  border-radius: 2rem;
  transition: all 0.5s;
  overflow: hidden;
  z-index: 0;
  &.color1 {
    background-color: #ff7f39ff;
    left: 0;
    bottom: 0;
    top: 0;
    margin-right: 2.5rem;
    transform: translate(0, -50%);
  }
  &.color2 {
    background-color: #ad96e9ff;
    left: 50%;
    top: 0;
    bottom: 0;
    transform: translate(-50%, -50%);
  }
  &.color3 {
    background-color: #40c4ceff;
    top: 0;
    right: 0;
    bottom: 0;
    margin-left: 2.5rem;
    transform: translate(0, -50%);
  }
  img {
    transition: all 0.5s;
    height: auto;
    width: 100%;
    position: absolute;
    left: 0;
  }
  p {
    text-align: start;
  }
  .title {
    transition: all 0.5s;
    color: black;
    font-family: bold;
    font-size: 2.7rem;
    letter-spacing: -1px;
  }
  .content {
    margin-top: 5rem;
    color: white;
    font-size: 2.8rem;
    letter-spacing: -1px;
    line-height: 4rem;
  }
  .bottom_div {
    cursor: $point_4, pointer;
    box-sizing: border-box;
    height: 39rem;
    transition: all 0.5s;
    bottom: calc(-100% + 18rem);
    left: 0;
    right: 0;
    position: absolute;
    padding: 3.1rem 0 3.5rem 4rem;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    background: transparent;
  }

  &:hover {
    width: 45rem;
    height: 60rem;
    transition: all 0.5s;
    z-index: 100;
    img {
      transition: all 0.5s;
      height: auto;
      width: 100%;
    }
    .title {
      transition: all 0.5s;
      color: white;
      font-size: 3.6rem;
      letter-spacing: -1px;
    }
    .bottom_div {
      transition: all 0.5s;
      bottom: 0;
      background: black;
    }
    .color1 {
      top: 0;
    }
  }
}
@media screen and (max-width: 1000px) {
  .father_div {
    width: 34.4rem;
    height: auto;
    margin-top: 0.7rem;
    transform: none;
  }
  .item_div {
    position: relative;
    height: 19.8rem;
    width: 34.4rem;
    transform: none;
    margin-top: 2.3rem;
    img {
      position: relative;
    }
    .bottom_div {
      position: relative;
      padding-top: 0;
    }
    &.color1 {
      transform: none;
      margin-right: 0;
    }
    &.color2 {
      transform: none;
      left: 0;
    }
    &.color3 {
      transform: none;
      margin-left: 0;
    }
    .title {
      font-size: 2.3rem;
      letter-spacing: 0;
    }
    .content {
      margin-top: 2rem;
      font-size: 1.7rem;
      line-height: 2.5rem;
      letter-spacing: 0;
    }
    .bottom_div {
      height: 26.5rem;
      padding: 0 2.7rem 2.7rem;
      bottom: 1rem;
    }
    &:hover {
      width: 34.4rem;
      height: 42.5rem;
      .title {
        transition: all 0.5s;
        color: white;
        font-size: 2.3rem;
        letter-spacing: 0;
      }
      .bottom_div {
        padding: 2.7rem;
      }
    }
  }
}
</style>
