<template>
  <div>
    <div class="width_div padding_top_170">
      <div class="item_div" v-for="(item, i) in subs" :key="i">
        <div class="title_div">
          <div class="left_div">
            <p class="p1" :class="i == 0 ? 'title' : ''">{{ item.subTitle }}</p>
            <p v-if="i == 0" class="p3">{{ Detail.typeName }}</p>
          </div>
          <p class="p2">{{ item.subContent }}</p>
        </div>
        <div class="imgs_div" v-for="(media, index) in item.media" :key="index">
          
          <el-image class="img1" v-if="media.type != 2&&index==0" :src="media.url1"/>
          <el-image class="img1" v-if="media.type != 2&&index!=0" :src="media.url1" lazy/>
          <el-image class="img2" v-if="media.type == 1" :src="media.url2" lazy/>
          <video
            v-if="media.type == 2||media.type==3"
            loop="loop"
            :muted="media.type==2&&!isMiniWindows"
            :autoplay="media.type==2&&!isMiniWindows"
            :poster="media.url2"
            :controls="media.type==3||isMiniWindows"
            >
            <source :src="media.url1" type="video/mp4" />
          </video>
          <!-- <video
          v-if="media.type == 2 && !media.url2"
          loop="loop"
          controls
          >
            <source :src="media.url1" type="video/mp4" />
          </video> -->
        </div>
      </div>
      <p class="time">作品时间 / {{ Detail.time }}</p>
    </div>
    <div class="width_div padding_top_170 padding_bottom_200">
      <p class="title_arrow">我们的作品</p>
      <cases-list
        v-if="!isMiniWindows"
        pageSize="100"
        :listID="otherList"
      ></cases-list>
      <phone-cases-banner v-else pageSize="100" :listID="otherList"></phone-cases-banner>
      <p class="more gray margin_top margin_center" @click="pushTo('/Works')">
        查看更多
      </p>
    </div>
  </div>
</template>
<script>
import * as API from "@/api/index";
import CasesList from "./view/CasesList.vue";
import PhoneCasesBanner from "./view/PhoneCasesBanner.vue";
export default {
  components: { CasesList, PhoneCasesBanner },
  data() {
    return {
      Detail: "",
      list: "",
      subs: [],
      otherList: [],
      isMiniWindows: false,
      clientWidth: 0,
    };
  },
  watch: {
    clientWidth(newVal, oldVal) {
      // console.log(newVal); //浏览器窗口变化时，打印宽度。
      if (newVal < 1200) {
        this.isMiniWindows = true;
      } else {
        this.isMiniWindows = false;
      }
    },
    $route(to, from) {
      //   console.log(to.path);
      if (to.path.indexOf("/WorksDetail") != -1) {
        window.scrollTo({top:0, left:0,behavior: "instant"});
        this.getDetail();
      }
      //   var param = this.$route.query.param;
      //   this.path = param;
      //   console.log("param:" + param);
      //   this.navtoPartPath(this.path);
    },
  },
  created() {},
  computed: {},
  mounted() {
    this.getDetail();
    window.scrollTo({top:0, left:0,behavior: "instant"});
    let that = this;
    var clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    that.clientWidth = clientWidth;
    window.onresize = () => {
      return (() => {
        var clientWidth =
          document.documentElement.clientWidth || document.body.clientWidth;
        that.clientWidth = clientWidth;
      })();
    };
  },
  methods: {
    getDetail() {
      this.Detail = {};
      this.otherList.length = 0;
      let news_id = window.location.href.split("id=")[1];
      API.getCasesDetail(news_id)
        .then((res) => {
          console.log(res);
          var status = res.code;
          if (status === 0) {
            this.Detail = res.news;
            this.subs = JSON.parse(this.Detail.content);
            if (this.Detail.titleEn) {
              this.otherList.push(parseInt(this.Detail.titleEn));
              this.otherList.push(parseInt(this.Detail.simpleEn));
              this.otherList.push(parseInt(this.Detail.contentEn));
            }
          }
        })
        .catch(() => {});
    },
    pushTo(path) {
      this.$router.push(path);
    },
  },
};
</script>
<style lang="scss" scoped>
.title_div {
  display: flex;
  margin-top: 9rem;
  margin-bottom: 9rem;
  .left_div {
    width: 50%;
  }
  .p1 {
    text-align: start;
    font-size: 3.6rem;
    letter-spacing: -1px;
  }
  .p3 {
    text-align: start;
    color: $color999;
  }
  .p2 {
    width: 50%;
    line-height: 3.6rem;
    text-align: justify;
  }
}
.imgs_div {
background: white;
  display: flex;
  width: 100%;
  margin-bottom: 2.5rem;
  .img1 {
    flex: 1;
    width: 50%;
    max-width: 100%;
  }
  .img2 {
    width: 50%;
    margin-left: 2.5rem;
  }
  video {
    width: 100%;
  }
}
.time {
  margin-top: 5rem;
  color: $color666 !important;
  text-align: end;
}
@media (max-width: 1000px) {
  .width_div.padding_top_170 {
    padding-top: 9rem;
  }
  .title_div {
    flex-direction: column;
    margin-top: 3rem;
    margin-bottom: 3rem;
    .left_div {
      width: 100%;
    }
    .p1 {
      font-size: 1.7rem;
      letter-spacing: 0;
      margin-left: 2.7rem;
      &.title {
        font-size: 2.7rem;
        letter-spacing: -1px;
      }
    }
    .p3 {
      margin-left: 2.7rem;
      font-size: 1.3rem;
      text-align: start;
      color: $color999;
      margin-bottom: 1.5rem;
    }
    .p2 {
      margin-left: 2.7rem;
      margin-right: 2.7rem;
      margin-top: 2rem;
      line-height: 2.1rem;
      font-size: 1.1rem;
      width: fit-content;
    }
  }
  .imgs_div {
    margin-bottom: 1.5rem;
    flex-direction: column;
    .img1{
      width:100%;

    }
    .img2{
      width:100%;
      margin-left: 0;
      margin-top:1.5rem;
    }
  }
  .time {
    font-size: 1.1rem;
    margin-top: 2.5rem;
    color: $color666 !important;
    text-align: center;
  }
  .title_arrow {
    font-size: 1.7rem;
    margin-left: 2.7rem;
    line-height: 2.3rem;
    &::after {
      background: none;
    }
  }
}
</style>