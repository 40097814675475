<template>
  <div id="app">
    <el-container class="color_container">
      <el-header>
        <nav-bar></nav-bar>
      </el-header>
      <el-main class="color_main">
        <keep-alive>
          <router-view :key="$route.fullpath"></router-view>
        </keep-alive>
      </el-main>
      <el-footer class="color_footer">
        <nav-footer></nav-footer>
      </el-footer>
    </el-container>
    <div class="color_div">
      <div
        class="width_div flex between end padding_top_100 padding_bottom_120"
      >
        <p>Deno Brand Planning</p>
        <img src="../../static/img/bottom_logo.svg" />
      </div>
    </div>
    <div class="side_div show" id="side_div">
      <div class="goback_top_img" @click="goTopback()"></div>
      <div class="talk_div" :class="openTaklState ? 'open' : ''">
        <div class="flex_div">
          <p class="title" @click="!openTaklState ? openTalk() : ''">
            {{ openTaklState ? "快捷留言" : "留言咨询" }}
          </p>

          <div
            class="img_div"
            :class="openTaklState ? 'close' : ''"
            @click="openTalk()"
          ></div>
        </div>
        <el-input
          v-model="liuyan1"
          type="textarea"
          resize="none"
          :rows="2"
          class="input_div1"
          placeholder="请在此输入留言的内容，我们会尽快与您取得联系。"
        ></el-input>
        <div class="flex_div">
          <el-input
            v-model="liuyan2"
            class="input_div2"
            placeholder="联系电话 (必填)"
          ></el-input>
          <el-input
            v-model="liuyan3"
            class="input_div2"
            placeholder="姓名称呼"
          ></el-input>
        </div>
        <div class="flex_div">
          <p class="baidu_talk" @click="openBaidu()">
            需要在线咨询？请点击这里。
          </p>
          <div class="submit">
            <p @click="toSubmit()">{{ submitState }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as API from "@/api/index";
import NavBar from "./navbar/NavBar.vue";
import NavFooter from "./navbar/NavFooter.vue";
import side_back_img from "../../static/img/side_back_img.svg";
import side_back_img_w from "../../static/img/side_back_img2.svg";
import talk_img from "../../static/img/side_talk_img.svg";
import talk_close_img from "../../static/img/search_close_img.svg";
export default {
  components: {
    NavBar,
    NavFooter,
  },
  name: "App",
  data() {
    return {
      oldScrollTop: 0,
      isShow: false,
      openTaklState: false,
      side_back_img: side_back_img,
      side_back_img_w: side_back_img_w,
      talk_img: talk_img,
      talk_close_img: talk_close_img,
      liuyan1: "",
      liuyan2: "",
      liuyan3: "",
      submitState: "提交",
    };
  },
  created() {},
  mounted() {
    // 监听页面滚动事件
    window.addEventListener("scroll", this.scrolling);
  },
  methods: {
    goTopback() {
      window.scrollTo(0, 0);
    },
    //判断返回顶部按钮是否出现，开始数字动画
    scrolling() {
      // 滚动条距文档顶部的距离（做兼容处理）===》不懂的可以结合画图理清逻辑
      var pageHeight = document.body.scrollHeight;
      let scrollTop =
        window.scrollY ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop;
      // console.log("oldScrollTop==" + this.oldScrollTop);
      // console.log("pageHeight==" + pageHeight);
      if (this.oldScrollTop > pageHeight / 3) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    },
    openTalk() {
      this.openTaklState = !this.openTaklState;
    },
    toSubmit() {
      if (this.liuyan2) {
        var p = {
          name: this.liuyan3,
          content: this.liuyan1,
          tel: this.liuyan2,
        };
        API.saveArchitecture(p)
          .then((res) => {
            var status = res.code;
            if (status === 0) {
              // alert("提交成功");
              this.submitSuccess();
            }
          })
          .catch(() => {});
      }
    },
    submitSuccess() {
      this.liuyan1 = "";
      this.liuyan2 = "";
      this.liuyan3 = "";
      this.submitState = "已提交";
      let _this = this;
      setTimeout(function () {
        _this.openTaklState = false;
        _this.submitState = "提交";
      }, 500);
    },
    openBaidu() {
      this.liuyan1 = "";
      this.liuyan2 = "";
      this.liuyan3 = "";
      this.openTaklState = false;
      setTimeout(function () {
        window.open("https://affim.baidu.com/unique_18394083/chat?siteId=14390474&userId=18394083&siteToken=752b6a38cac9bc9dff676a1c52eb57f2","_blank");
      }, 300);
    },
  },
  destroyed() {
    // 移除事件监听
    window.removeEventListener("scroll", this.scrolling);
  },
};
</script>

<style lang="scss" scoped>
.side_div {
  cursor: $point_3, pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  right: 8rem;
  // left:92vw;
  bottom: 25%;
  z-index: 9999;
}

.goback_top_img {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  cursor: $point_3, pointer;
  background: url("../../static/img/side_back_img.svg");
  transition: all 0.5s;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  &:hover {
    transition: all 0.5s;
    background: url("../../static/img/side_back_img2.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}
.talk_div {
  cursor: $point_2, pointer;
  border-radius: 3rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  width: auto;
  align-items: center;
  overflow: hidden;
  height: 6rem;
  transition: all 0.5s;
  box-sizing: border-box;
  .baidu_talk {
    line-height: 1;
    cursor: $point_1, pointer;
    color: black;
    font-size: 1.8rem;
    background: #ffba9dff;
    border-radius: 2rem;
    padding: 1.6rem;
    margin-top: 1rem;
    opacity: 0;
    width: 0;
  }
  .submit {
    line-height: 1;
    margin-top: 1rem;
    opacity: 0;
    width: 0;
    display: flex;
    justify-content: end;
    p {
      cursor: $point_3, pointer;
      width: fit-content;
      color: black;
      font-size: 1.8rem;
      background: #ffd900ff;
      border-radius: 2rem;
      padding: 1.6rem;
      &:hover {
        background: black;
        color: white;
        font-family: bold;
      }
    }
  }
  /deep/ .el-input__inner {
    border: none;
    border-radius: 2rem;
    height: 100%;
    font-size: 1.8rem;
    font-family: Poppins-Medium, medium;
    text-align: start;
  }
  /deep/ .el-textarea__inner {
    padding: 1.8rem;
    border: none;
    border-radius: 2rem;
    height: 100%;
    font-size: 1.8rem;
    font-family: Poppins-Medium, medium;
    text-align: start;
  }
  .flex_div {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .input_div1 {
    opacity: 0;
    width: 0;
    height: 14rem;
    margin-top: 1.8rem;
  }
  .input_div2 {
    margin-top: 1rem;
    opacity: 0;
    width: 0;
    height: 5.5rem;
    &:last-child {
      margin-left: 1rem;
    }
  }
  .img_div {
    cursor: $point_3, pointer;
    height: 6rem;
    width: 6rem;
    transition: all 0.5s;
    background: url("../../static/img/side_talk_img.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    &:hover {
      transition: all 0.5s;
      background: url("../../static/img/side_talk_img2.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
    &.close {
      width: 3rem;
      height: 2rem;
      cursor: $point_1, pointer;
      transition: all 0.5s;
      background: url("../../static/img/search_close_img.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
  .title {
    // cursor:$point_3, pointer;
    transition: all 0.5s;
    color: black;
    font-family: bold;
    margin: 0;
    box-sizing: border-box;
    opacity: 0;
    width: auto;
    max-width: 0;
    overflow: hidden;
    white-space: nowrap;
  }

  &:hover {
    transition: all 0.5s;
    background: #f4f4f4eb;
    width: auto;
    .title {
      transition: all 0.5s;
      max-width: 19.5rem;
      opacity: 1;
      margin:0 2rem;
    }
  }
  &.open {
    width: 58rem;
    height: 35rem;
    background: #f4f4f4eb;
    padding: 2rem 1.4rem;
    img {
      width: 1.6rem;
      height: 1.6rem;
      margin-right: 1rem;
    }
    .title {
      color: $color666;
      font-family: medium;
      transition: all 0.5s;
      max-width: 19.5rem;
      opacity: 1;
      margin-left: 1rem;
    }
    .input_div1 {
      opacity: 1;
      width: 100%;
    }
    .input_div2 {
      opacity: 1;
      width: 100%;
    }
    .baidu_talk {
      opacity: 1;
      width: 100%;
    }
    .submit {
      opacity: 1;
      width: 100%;
    }
  }
}

.show {
  transition: all 0.5s;
  opacity: 1;
}

.no_show {
  opacity: 0;
  width: 0;
  height: 0;
  transition: all 0.5s;
}
.color_main {
  background: white;
}
.color_container {
  position: relative;
  z-index: 100;
}
.color_footer {
  background: white;
  margin-bottom: 165rem;
}
.color_div {
  height: 100%;
  display: flex;
  z-index: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: $colorFFD900;
  // margin: 0 calc((100vw - 100%) * -1 + 1.7rem) 0 0;
  img {
    width: 21rem;
    height: 80rem;
  }
  p {
    font-size: 4rem;
    letter-spacing: -1px;
    color: black !important;
  }
}

@media (max-width: 1000px) {
  .side_div {
    right: 8px;
  }
  .goback_top_img {
    // right: 0.5rem;
    width: 3.8rem;
    height: 3.8rem;
    &:hover {
      background: url("../../static/img/side_back_img.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
  .talk_div {
    border-radius: 2rem;
    height: 3.8rem;
    .input_div1 {
      height: 10.5rem;
    }
    .input_div2 {
      height: 3.2rem;
    }
    .baidu_talk {
      font-size: 1rem;
      border-radius: 1rem;
      padding: 1.2rem 1rem;
    }
    .submit {
      p {
        padding: 1.2rem 1rem;
        border-radius: 1rem;
        font-size: 1rem;
      }
    }
    .title {
      font-size: 1.5rem;
    }
    &:hover {
      transition: all 0.5s;
      background: transparent;
      // width: auto;
      .title {
        transition: all 0.5s;
        // max-width: 3.8rem;
        opacity: 0;
      }
    }
    img {
      width: 3.8rem;
      height: 3.8rem;
    }
    .img_div {
      height: 3.8rem;
      width: 3.8rem;

      &:hover {
        background: url("../../static/img/side_talk_img.svg");
      }
      &.close {
        
        background: url("../../static/img/search_close_img.svg");
        width: 1.5rem;
        height: 1.5rem;
      }
    }
    &.open {
      background: #f4f4f4eb;
      height: 26.3rem;
      width: 35.9rem;
      padding: 1.9rem 2.7rem;
      backdrop-filter: blur(14px);
      .title {
        width: auto;
        opacity: 1;
      }
    }
    /deep/ .el-input__inner {
      font-size: 1rem;
      border-radius: 1rem;
    }
    /deep/ .el-textarea__inner {
      font-size: 1rem;
      border-radius: 1rem;
    }
  }
  .color_div {
    z-index: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: $colorFFD900;
    padding: 0 5rem;
    img {
      width: 7rem;
      height: 25rem;
    }
    p {
      font-size: 1.3rem;
      letter-spacing: 0px;
    }
  }
  .width_div {
    width: 100%;
    &.padding_bottom_120 {
      padding-bottom: 3rem;
    }
    &.padding_top_100 {
      padding-top: 10rem;
    }
  }
  .color_footer {
    margin-bottom: 100rem;
  }
}
</style>
