<template>
  <div>
    <div class="top_img black">
      <div class="top_img_div">
        <video v-if="imgs[0].img.includes('.mp4')" muted autoplay loop="loop">
          <source
            :src="isMiniWindows ? imgs[0].phoneImg : imgs[0].img"
            type="video/mp4"
          />
        </video>
        <img v-else :src="isMiniWindows ? imgs[0].phoneImg : imgs[0].img" />
      </div>
      <div class="width_div top_txt_div">
        <div class="mid_left_div">
          <p class="p1" v-html="imgs[0].name"></p>
          <div class="mid_left_sub_div">
            <div>
              <p class="p2" v-html="imgs[0].nameEn"></p>
              <p class="p3" v-html="imgs[0].title"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="width_div padding_top_160 padding_bottom_120">
      <p class="start p_36">我们的原则</p>
    </div>
    <div
      class="pic_div"
      :class="'color' + (i + 1)"
      v-for="(item, i) in info"
      :key="i"
    >
      <div class="bg_div" :id="'bg_div_' + i">
        <img class="big_img" :src="item.img" :id="'big_img_' + i" />
        <div class="info_div" id="info_div">
          <img :src="item.cover" />
          <p class="p1">{{ item.titleEn }}</p>
          <p class="p2">{{ item.title }}</p>
          <p class="p3">{{ item.content }}</p>
        </div>
      </div>
    </div>
    <div class="business_div gray_bg">
      <div
        class="width_div between flex column end padding_top_120 padding_bottom_155"
      >
        <p class="start p_36 margin_bottom10 m4">我们提供三大核心服务</p>
        <business-view></business-view>
      </div>
    </div>
    <our-view-vue></our-view-vue>
    <div
      class="width_div relative between flex column end padding_top_170 padding_bottom_50"
    >
      <p class="start p_36 margin_bottom8">我们帮助这些品牌创造价值</p>
      <customers-view></customers-view>
    </div>
  </div>
</template>
<script>
import * as API from "@/api/index";
import BusinessView from "./view/BusinessView.vue";
import CustomersView from "./view/CustomersView.vue";
import OurViewVue from "./view/OurView.vue";
export default {
  components: { BusinessView, CustomersView, OurViewVue },
  data() {
    return {
      center: { lng: 0, lat: 0 },
      zoom: 12,
      data: {},
      info: [],
      imgs: [{ img: "" }],
      lng: 121.665663,
      lat: 30.024473,
      isMiniWindows: false,
      clientWidth: 0,
      originOffetY: 200,
    };
  },
  watch: {
    clientWidth(newVal, oldVal) {
      // console.log(newVal); //浏览器窗口变化时，打印宽度。
      if (newVal < 1200) {
        this.isMiniWindows = true;
      } else {
        this.isMiniWindows = false;
      }
    },
    $route(to, from) {
      if (to.path.indexOf("/About") != -1) {
        window.scrollTo(0, 0);
      }
    },
  },
  created() {},
  computed: {},
  mounted() {
    window.scrollTo(0, 0);
    this.getImg();
    this.getChemicalindustry();
    this.getInvestment();
    let that = this;
    var clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    that.clientWidth = clientWidth;
    window.onresize = () => {
      return (() => {
        var clientWidth =
          document.documentElement.clientWidth || document.body.clientWidth;
        that.clientWidth = clientWidth;
      })();
    };
    // 监听页面滚动事件
    this.calcBigImgPosition();
    window.addEventListener("scroll", this.calcBigImgPosition);
  },
  methods: {
    getImg() {
      var p = { title: "我们是" };
      API.getImgs(p)
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.imgs = res.list;
          }
        })
        .catch(() => {});
    },
    getInvestment() {
      API.getInvestment()
        .then((res) => {
          console.log(res);
          var status = res.code;
          if (status === 0) {
            this.data = res.page.list[0];
          }
        })
        .catch(() => {});
    },
    getChemicalindustry() {
      API.getChemicalindustry()
        .then((res) => {
          console.log(res);
          var status = res.code;
          if (status === 0) {
            this.info = res.page.list;
          }
        })
        .catch(() => {});
    },
    calcBigImgPosition() {
      // 滚动条距文档顶部的距离（做兼容处理）===》不懂的可以结合画图理清逻辑
      let scrollTop =
        window.scrollY ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      var innerHeight = window.innerHeight;

      var bg1 = document.getElementById("bg_div_0");
      var bg2 = document.getElementById("bg_div_1");
      var bg3 = document.getElementById("bg_div_2");
      var bg4 = document.getElementById("bg_div_3");

      var img1 = document.getElementById("big_img_0");
      var img2 = document.getElementById("big_img_1");
      var img3 = document.getElementById("big_img_2");
      var img4 = document.getElementById("big_img_3");

      if (bg1 && bg2 && bg3 && bg4 && img1 && img2 && img3 && img4) {
        var position_top_bg1 = bg1.offsetTop;
        var position_top_bg2 = bg2.offsetTop;
        var position_top_bg3 = bg3.offsetTop;
        var position_top_bg4 = bg4.offsetTop;

        // console.log("about scrollTop==", scrollTop);
        // console.log("about innerHeight==", innerHeight);
        // console.log("about position_top_bg1==", position_top_bg1);
        // console.log("about offsetHeight==", img1.offsetHeight);

        var seeHeight_1 =
          img1.offsetHeight -
          (position_top_bg1 + img1.offsetHeight - innerHeight) +
          scrollTop;
        var seeHeight_2 =
          img2.offsetHeight -
          (position_top_bg2 + img2.offsetHeight - innerHeight) +
          scrollTop;
        var seeHeight_3 =
          img3.offsetHeight -
          (position_top_bg3 + img3.offsetHeight - innerHeight) +
          scrollTop;
        var seeHeight_4 =
          img4.offsetHeight -
          (position_top_bg4 + img4.offsetHeight - innerHeight) +
          scrollTop;

        console.log("about seeHeight 1==", seeHeight_1);
        console.log("about seeHeight 2==", seeHeight_2);
        console.log("about seeHeight 3==", seeHeight_3);
        console.log("about seeHeight 4==", seeHeight_4);

        if (seeHeight_1 > 0 && seeHeight_1 < img1.offsetHeight) {
          img1.style.transform =
            "translateY(" +
            (img1.offsetHeight * 0.18 - seeHeight_1 / 5.5) +
            "px)";
        }

        if (seeHeight_2 > 0 && seeHeight_2 < img2.offsetHeight) {
          img2.style.transform =
            "translateY(" +
            (img2.offsetHeight * 0.18 - seeHeight_2 / 5.5) +
            "px)";
        }

        if (seeHeight_3 > 0 && seeHeight_3 < img3.offsetHeight) {
          img3.style.transform =
            "translateY(" +
            (img3.offsetHeight * 0.18 - seeHeight_3 / 5.5) +
            "px)";
        }

        if (seeHeight_4 > 0 && seeHeight_4 < img4.offsetHeight) {
          img4.style.transform =
            "translateY(" +
            (img4.offsetHeight * 0.18 - seeHeight_4 / 5.5) +
            "px)";
        }
      }
    },
  },
  destroyed() {
    // 移除事件监听
    window.removeEventListener("scroll", this.calcBigImgPosition);
  },
};
</script>
<style lang="scss" scoped>
.mid_left_div {
  margin-top: 29rem;
  width: 50%;
  display: flex;
  .mid_left_sub_div {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .p1 {
    color: white;
    text-align: start;
  }
  .p2 {
    @extend .p1;
    font-size: 4.8rem;
    margin-left: 6.5rem;
    line-height: 5.8rem;
    letter-spacing: -1px;
  }
  .p3 {
    @extend .p2;
    letter-spacing: normal;
    text-align: justify;
    margin-top: 11.2rem;
    font-size: 1.8rem;
    line-height: 3rem;
  }
}

.pic_div {
  height: 98.8rem;
  width: 100%;
  margin-top: -8.8rem;
  .bg_div {
    height: 100%;
    width: 73%;
    max-width: 140rem;
    margin: auto;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    position: relative;
    overflow: hidden;
    .big_img {
      position: absolute;
      height: 100%;
      // width: 100%;
      z-index: 10;
      right: 0;
      top: 0;
      // bottom: -4rem;
      object-fit: cover;
      transform: translateY(15rem);
    }
  }
  .info_div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    z-index: 100;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    img {
      width: 16rem;
      height: 16rem;
      margin: 5rem 0 4rem;
    }
    p {
      text-align: start;
      color: black;
    }
    .p1 {
      font-family: Poppins-Bold;
      font-size: 6.4rem;
      letter-spacing: -1px;
      line-height: 1;
    }
    .p2 {
      margin-top: 1rem;
      font-family: black;
      font-size: 4.2rem;
      letter-spacing: -1px;
      line-height: 1;
    }
    .p3 {
      max-width: 52rem;
      width: 35%;
      margin-top: 6rem;
      font-size: 1.8rem;
      line-height: 3rem;
    }
  }
  &.color1 {
    //背景色调整，上部分白，下部分黄
    background: linear-gradient(
      to bottom,
      transparent 0%,
      transparent 8.9%,
      #c5b5f1ff 8.9%,
      #c5b5f1ff 100%
    );
  }
  &.color2 {
    background: linear-gradient(
      to bottom,
      transparent 0%,
      transparent 8.9%,
      #ffba9dff 8.9%,
      #ffba9dff 100%
    );
  }
  &.color3 {
    background: linear-gradient(
      to bottom,
      transparent 0%,
      transparent 8.9%,
      #88dbdfff 8.9%,
      #88dbdfff 100%
    );
  }
  &.color4 {
    background: linear-gradient(
      to bottom,
      transparent 0%,
      transparent 8.9%,
      #fff2a8ff 8.9%,
      #fff2a8ff 100%
    );
  }
}

.business_div {
  display: flex;
  p {
    color: black;
  }
  &.gray_bg {
    background: linear-gradient(157deg, #f4f4f4 0%, #e8e8e8 100%);
  }
}

@media (max-width: 1000px) {
  .mid_left_div {
    flex-direction: column;
    margin-top: 11rem;
    width: 100%;
    .mid_left_sub_div {
      width: 94.5%;
    }
    .p1 {
      margin-left: 2.7rem;
      font-size: 1.7rem;
      line-height: 2.8rem;
    }
    .p2 {
      font-size: 2.3rem;
      line-height: 3.1rem;
      margin-top: 1rem;
    }
    .p3 {
      font-size: 1.1rem;
      line-height: 2.1rem;
      margin-top: 2.3rem;
    }
  }
  .width_div {
    p {
      &.m4 {
        padding-left: 4.2rem !important;
      }
      &.p_36 {
        font-size: 1.9rem;
        margin-left: 2.7rem;
      }
      &.margin_bottom8 {
        margin-bottom: 3.8rem;
      }
      &.margin_bottom10 {
        margin-bottom: 0;
      }
    }
    &.padding_top_120 {
      padding-top: 5.7rem;
    }
    &.padding_top_160 {
      padding-top: 5.7rem;
    }
    &.padding_top_170 {
      padding-top: 9.5rem;
    }
    &.padding_bottom_120 {
      padding-bottom: 5.7rem;
    }
    &.padding_bottom_155 {
      padding-bottom: 5.7rem;
    }
  }
  .pic_div {
    height: 54.8rem;
    margin-top: -1.9rem;
    .bg_div {
      width: 100%;
      background-size: cover;
      background-position: left;
      .big_img {
        height: auto;
        width: 100%;
        top: 0;
        /* right: -5rem; */
        bottom: unset;
        object-position: center;
      }
    }
    .info_div {
      padding: 0 4.2rem;
      justify-content: flex-end;
      img {
        width: 6rem;
        height: 6rem;
        margin: 0 0 2.5rem;
      }
      .p1 {
        font-size: 3.8rem;
        line-height: 3.1rem;
      }
      .p2 {
        font-size: 2.5rem;
        line-height: 3.1rem;
      }
      .p3 {
        width: 100%;
        font-size: 1.1rem;
        line-height: 2.1rem;
        margin-top: 3rem;
        margin-bottom: 5.7rem;
      }
    }
    &.color1 {
      //背景色调整，上部分白，下部分黄
      background: linear-gradient(
        to bottom,
        transparent 0%,
        transparent 3.5%,
        #c5b5f1ff 3.5%,
        #c5b5f1ff 100%
      );
    }
    &.color2 {
      background: linear-gradient(
        to bottom,
        transparent 0%,
        transparent 3.5%,
        #ffba9dff 3.5%,
        #ffba9dff 100%
      );
    }
    &.color3 {
      background: linear-gradient(
        to bottom,
        transparent 0%,
        transparent 3.5%,
        #88dbdfff 3.5%,
        #88dbdfff 100%
      );
    }
    &.color4 {
      background: linear-gradient(
        to bottom,
        transparent 0%,
        transparent 3.5%,
        #fff2a8ff 3.5%,
        #fff2a8ff 100%
      );
    }
  }
}
</style>