<template>
  <div class="footer-father">
    <div class="width_div flex between padding_top_120 padding_bottom_120">
      <div class="footer-left">
        <p
          v-if="!isMiniWindows"
          class="title_arrow white_arrow"
          @click="navtoTop('/Contact')"
        >
          {{ contact.name }}
        </p>
        <div class="qrcode_bottom_div">
          <div class="qrcode_icon_div">
            <img
              src="../../../static/img/qr-icon2.svg"
              :class="activeQrcode == 1 ? '' : 'gray_cover'"
              @mouseenter="qrcode(1)"
            />
            <img
              src="../../../static/img/qr-icon1.svg"
              class="margin"
              :class="activeQrcode == 2 ? '' : 'gray_cover'"
              @mouseenter="qrcode(2)"
            />
          </div>
          <div class="qrcode">
            <img
              :style="{
                'background-image': `url(${encodeURI(
                  activeQrcode == 1 ? contact.tel : contact.email
                )})`,
              }"
            />
            <div class="p_div">
              <p class="p1" :class="activeQrcode == 1 ? 'p_show' : ''">扫一扫我们的客服</p>
              <p class="p2" :class="activeQrcode == 2 ? 'p_show' : ''">关注我们的公众号</p>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-right">
        <!-- <div class="line2"></div> -->
        <p
          v-if="isMiniWindows"
          class="title_arrow white_arrow"
          @click="navtoTop('/Contact')"
        >
          {{ contact.name }}
        </p>
        <div>
          <div class="content1" v-html="contact.phone"></div>
          <div class="content2" v-html="contact.addr"></div>
        </div>
        <p class="footer-bottom">
          <span href="https://beian.miit.gov.cn/">浙ICP备15041898号-1</span>
          &ensp;©&ensp;2024 Copyright 德诺(宁波)品牌策划有限公司. All Rights
          Reserved.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import * as API from "@/api/index";
import * as navBar from "./navbar";
export default {
  data() {
    return {
      contact: [],
      activeQrcode: 1,
      isMiniWindows: false,
      clientWidth: 0,
    };
  },
  computed: {
    nav_title_list() {
      return navBar.getList();
    },
  },
  components: {},
  watch: {
    clientWidth(newVal, oldVal) {
      // console.log(newVal); //浏览器窗口变化时，打印宽度。
      if (newVal < 1200) {
        this.isMiniWindows = true;
      } else {
        this.isMiniWindows = false;
      }
    },
  },
  mounted() {
    this.Init();
    let that = this;
    var clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    that.clientWidth = clientWidth;
    window.onresize = () => {
      return (() => {
        var clientWidth =
          document.documentElement.clientWidth || document.body.clientWidth;
        that.clientWidth = clientWidth;
      })();
    };
  },

  methods: {
    Init() {
      this.getData();
    },

    getData() {
      API.getCallus()
        .then((res) => {
          console.log(res);
          var status = res.code;
          if (status === 0) {
            this.contact = res.page.list[1];
          }
        })
        .catch(() => {});
    },

    qrcode(i) {
      this.activeQrcode = i;
    },
    navto(path) {
      this.$router.push(path);
    },
    jumpTo(url) {
      if (url) {
        window.open(url, "_blank");
      }
    },
    handleCommand(command) {
      // this.$message("click on item " + command);
      this.jumpTo(command);
    },
    scrollNavto(index, path, name) {
      if (!name) {
        this.navto(path);
      } else {
        this.$router.push({
          path: `${path}`,
          query: { param: name },
        });
      }
    },
    navtoTop(path) {
      window.scrollTo(0, 0);
      // if (this.isWechat()) {
        // this.$router.replace(path);
      // } else {
        if(path){
          this.$router.push(path);
        }
      // }
    },
  },
  created() {
    // window.addEventListener("onmessageWS", this.addAnimatedIn);
  },
  destroyed() {
    // 移除事件监听
    window.removeEventListener("scroll", this.windowScrollListener);
  },
  updated() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.color_div {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: $colorFFD900;
  img {
    width: 21rem;
    height: 80rem;
  }
  p {
    font-size: 4rem;
    color: black !important;
  }
}
@media (max-width: 1000px) {
  .title_arrow {
    cursor: $point_1, pointer;
    color: black;
    font-size: 1.9rem;
    width: fit-content;
    transition: all 0.5s;
    letter-spacing: -1px;
    line-height: 2.2rem;

    &::after {
      content: "";
      width: 1.2rem;
      height: 1.2rem;
      background: url("~@/../static/img/title-arrow-right.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      display: inline-block;
      top: 0;
      bottom: 0;
      margin: auto 0 auto 1rem;
    }

    &.white_arrow {
      color: white;
      cursor: $point_3, pointer;

      &::after {
        content: "";
        width: 7px;
        height: 8px;
        background: url("~@/../static/img/title-arrow-right-w.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        display: inline-block;
        top: 0;
        bottom: 0;
        margin: auto 0 auto 3px;
      }
    }
  }
  .width_div {
    &.flex {
      flex-direction: column-reverse;
    }
    &.padding_bottom_120 {
      padding-bottom: 3rem;
    }
    &.padding_top_120 {
      padding-top: 5.7rem;
    }
  }
}
</style>
