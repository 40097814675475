<template>
  <div>
    <div class="swiper_bg">
      <div class="swiper-container mySwiper casesSwiper" id="casesSwiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in news" :key="index">
            <div
              class="cases_item_div bg_div"
              @click="navTo('/WorksDetail/id=' + item.id)"
            >
              <video
                v-if="item.cover.includes('.mp4')"
                muted
                autoplay
                loop="loop"
              >
                <source :src="item.cover" type="video/mp4" />
              </video>
              <img v-else :src="item.cover" />
              <div class="item_div">
                <p class="p1">{{ item.title }}</p>
                <p class="p2">{{ item.simple }}</p>
                <p class="p3">{{ item.typeName }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper, { Autoplay } from "swiper";
// Swiper.use(Autoplay);
import "swiper/swiper-bundle.css";
import * as API from "@/api/index";
export default {
  props: ["pageSize", "listID"],
  data() {
    return {
      isMiniWindows: false,
      clientWidth: 0,
      activeIndex: 0,
      news: [],
      swiper: Object,
    };
  },
  created() {},
  watch: {
    //监听轮播数据变化后再创建实例
    listID(newdata, olddata) {
      // console.log("watch newdata==", newdata);
      // console.log("watch olddata==", olddata);
      this.getCases();
    },
    news() {
      this.$nextTick(() => {
        this.initSwiper();
      });
    },
  },
  computed: {},
  mounted() {
    if (!this.listID) {
      this.getCases();
    }
  },
  destroyed() {},
  methods: {
    getCases() {
      this.news.length = 0;
      var data = {
        page: 1,
        limit: this.pageSize,
      };
      API.getCases(data)
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            if (this.listID) {
              for (var i = 0; i < this.listID.length; i++) {
                var id = this.listID[i];
                // console.log("i==", i);
                for (var j = 0; j < res.page.list.length; j++) {
                  // console.log("j==", j);
                  var item = res.page.list[j];
                  if (item.id == id) {
                    // console.log("push==", id);
                    this.news.push(item);
                    break;
                  }
                }
              }
            } else {
              this.news = res.page.list;
            }
          }
        })
        .catch(() => {});
    },
    initSwiper() {
      let that = this;
      this.$nextTick(() => {
        that.swiper = new Swiper(".casesSwiper", {
          slidesPerView: "auto",
          centeredSlides: true,
          loop: true,
          spaceBetween: 15,
          // autoplay: {
          //   disableOnInteraction: false,
          //   delay: 3000,
          // },
        });
      }, 600);
    },
    jumpTo(url) {
      if (url) {
        window.open(url, "_blank");
      }
    },
    navTo(path) {
      this.$router.push(path);
    },
  },
};
</script>
<style lang="scss" scoped>
.cases_item_div {
  cursor: $point_3, pointer;
  margin-top: 2.3rem;
  height: 35.4rem;
  position: relative;
  // width: 34.4rem;
  // box-sizing: border-box;
  img {
    width: 100%;
    height: 100%;
  }
  video {
    width: 100%;
    height: 100%;
  }
  .item_div {
    transition: all 0.5s;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-sizing: content-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }
  .p1 {
    margin-left: 2.7rem;
    text-align: start;
    color: white;
    font-size: 1.5rem;
    line-height: 1.1rem;
  }
  .p2 {
    margin-left: 2.7rem;
    text-align: start;
    color: white;
    letter-spacing: -1px;
    transition: all 0.5s;
    font-size: 2.3rem;
    margin-top: 8px;
    margin-bottom: 1rem;
    line-height: 2.9rem;
  }
  .p3 {
    line-height: 1.2;
    opacity: 1;
    height: auto;
    margin-left: 2.7rem;
    text-align: start;
    color: white;
    transition: all 0.5s;
    font-size: 1.3rem;
    margin-bottom: 3.2rem;
  }
}
.banner-img {
  width: 100%;
  height: 100%;
  // aspect-ratio: 16/9;
  object-fit: cover;
  // border-radius: 2rem;
}
/deep/ .swiper-slide {
  width: 80%;
  transform: translateX(-3rem);
}
@media screen and (max-width: 1000px) {
}
</style>