<template>
  <div>
    <div class="width_div padding_bottom_350">
      <div class="title_div">
        <p class="p1">案例作品</p>
        <p class="p2">旨在创造价值</p>
      </div>
      <cases-list pageSize="999"></cases-list>
    </div>
  </div>
</template>
  <script>
import CasesList from "./view/CasesList.vue";
export default {
  components: { CasesList },
  data() {
    return {
      isMiniWindows: false,
      clientWidth: 0,
    };
  },
  watch: {
    clientWidth(newVal, oldVal) {
      // console.log(newVal); //浏览器窗口变化时，打印宽度。
      if (newVal < 1200) {
        this.isMiniWindows = true;
      } else {
        this.isMiniWindows = false;
      }
    },
    $route(to, from) {
      if (to.path.indexOf("/Works") != -1) {
        window.scrollTo(0, 0);
        var param = this.$route.query.param;
        if (param) {
          console.log("Works param:" + param);
          this.type = param;
        }
        // this.getList();
        // this.getImg();
        // if (from.path.indexOf("NewsDetail") == -1) {
        // }
      }
    },
  },
  created() {},
  computed: {
    nav_title() {
      return navBar.getList();
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    let that = this;
    var clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    that.clientWidth = clientWidth;
    window.onresize = () => {
      return (() => {
        var clientWidth =
          document.documentElement.clientWidth || document.body.clientWidth;
        that.clientWidth = clientWidth;
      })();
    };
  },
  methods: {
    pushTo(path) {
      this.$router.push(path);
    },
    navItemClick(index) {
      this.type = index + 1;
      let url = this.changeURLParam(window.location.href, "param", this.type); // 修改 URL 参数
      console.log("url", url); //修改完之后的地址
      history.pushState("", "", url); // 不刷新页面
      this.currentPage = 1;
      // this.getList();
    },
    changeURLParam(url, type, value) {
      let reg = eval("/([?|&]" + type + "=)[^&]*/gi");
      value = value.toString().replace(/(^\s*)|(\s*$)/g, ""); // 移除首尾空格
      let url2;
      if (!value) {
        // remove
        url2 = url.replace(reg, "");
      } else {
        if (url.match(reg)) {
          // edit
          url2 = url.replace(reg, "$1" + value);
        } else {
          // add
          url2 = url + (url.indexOf("?") > -1 ? "&" : "?") + type + "=" + value;
        }
      }
      return url2;
    },
  },
};
</script>
  <style lang="scss" scoped>
.title_div {
  margin-top: 29rem;
  margin-bottom: 8rem;
  p {
    text-align: start;
  }
  .p1 {
    font-size: 2.6rem;
    letter-spacing: -1px;
  }
  .p2 {
    font-size: 3.6rem;
    letter-spacing: -1px;
  }
}
@media (max-width: 1000px) {
  .title_div {
    margin-top: 11rem;
    margin-left: 2.7rem;
    margin-bottom: 0;
    .p1 {
      font-size: 1.7rem;
      letter-spacing: 0px;
    }
    .p2 {
      font-size: 2.5rem;
      letter-spacing: -1px;
    }
  }
  .width_div {
    &.padding_bottom_350 {
      padding-bottom: 16.2rem;
    }
  }
}
</style>