<template>
  <div>
    <div class="top_img">
      <div class="top_img_div">
        <video v-if="imgs[0].img.includes('.mp4')" muted autoplay loop="loop">
          <source
            :src="isMiniWindows ? imgs[0].phoneImg : imgs[0].img"
            type="video/mp4"
          />
        </video>
        <img v-else :src="isMiniWindows ? imgs[0].phoneImg : imgs[0].img" />
      </div>
      <div class="width_div top_txt_div">
        <div class="mid_left_div">
          <p class="p1" v-html="imgs[0].name"></p>
          <div class="mid_left_sub_div">
            <div>
              <p class="p2" v-html="imgs[0].nameEn"></p>
              <p class="p3" v-html="imgs[0].title"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="width_div flex padding_top_170 padding_bottom_200 bus_div">
      <p>{{ infoData.name }}</p>
      <div class="tel_div">
        <p v-html="infoData.phone"></p>
        <div class="wechat_div">
          <div>
            <img :src="infoData.tel" />
            <p>客服微信</p>
          </div>
          <div>
            <img :src="infoData.email" />
            <p>微信公众号</p>
          </div>
        </div>
      </div>
      <div class="addr_div">
        <p v-html="infoData.addr"></p>
      </div>
    </div>
    <div class="width_div">
      <div class="join_title_div">
        <p class="p1">{{ imgs2[0].name }}</p>
        <p class="p2">{{ imgs2[0].nameEn }}</p>
      </div>
    </div>
    <div class="width_div flex join_img_div padding_bottom_350">
      <div class="img_div">
        <video v-if="imgs2[0].img.includes('.mp4')" muted autoplay loop="loop">
          <source :src="imgs2[0].img" type="video/mp4" />
        </video>
        <img v-else :src="imgs2[0].img" />
      </div>
      <div class="info_div">
        <p class="p1" v-html="imgs2[0].title"></p>
        <div class="jobs_div">
          <el-collapse accordion @change="handleChange" v-model="activeName">
            <el-collapse-item v-for="(item, i) in jobs" :key="i" :name="i + 1">
              <template slot="title">
                <p
                  class="title"
                  :class="activeName > 0 && activeName == i + 1 ? 'black' : ''"
                >
                  {{ item.name }}
                </p>
              </template>
              <p class="content" v-html="item.nameEn"></p>
            </el-collapse-item>
          </el-collapse>
        </div>
        <p class="p2" v-html="joinData.phone"></p>
        <p class="p1" v-html="joinData.addr"></p>
      </div>
    </div>
  </div>
</template>
<script>
import * as API from "@/api/index";
export default {
  components: {},
  data() {
    return {
      center: { lng: 0, lat: 0 },
      zoom: 12,
      infoData: {},
      joinData: {},
      imgs: [{ img: "" }],
      imgs2: [{ img: "" }],
      jobs: [],
      lng: 121.665663,
      lat: 30.024473,
      isMiniWindows: false,
      clientWidth: 0,
      activeName: "-1",
    };
  },
  watch: {
    clientWidth(newVal, oldVal) {
      // console.log(newVal); //浏览器窗口变化时，打印宽度。
      if (newVal < 1200) {
        this.isMiniWindows = true;
      } else {
        this.isMiniWindows = false;
      }
    },
    $route(to, from) {
      if (to.path.indexOf("/Contact") != -1) {
        window.scrollTo(0, 0);
      }
    },
  },
  created() {},
  computed: {},
  mounted() {
    window.scrollTo(0, 0);
    this.getImg();
    this.getImg2();
    this.getData();
    this.getJobs();
    let that = this;
    var clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    that.clientWidth = clientWidth;
    window.onresize = () => {
      return (() => {
        var clientWidth =
          document.documentElement.clientWidth || document.body.clientWidth;
        that.clientWidth = clientWidth;
      })();
    };
  },
  methods: {
    getImg() {
      var p = { title: "联系我们" };
      API.getImgs(p)
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.imgs = res.list;
          }
        })
        .catch(() => {});
    },
    getImg2() {
      var p = { title: "加入我们" };
      API.getImgs(p)
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.imgs2 = res.list;
          }
        })
        .catch(() => {});
    },
    handler({ BMap, map }) {
      console.log(BMap, map);
      var that = this;
      this.center.lng = this.lng;
      this.center.lat = this.lat;
      var point = new BMap.Point(this.lng, this.lat);
      map.enableScrollWheelZoom(true);
      map.centerAndZoom(point, that.zoom);
      map.addEventListener("zoomend", function () {
        // alert("地图缩放至：" + this.getZoom() + "级");
        map.panTo(new BMap.Point(that.lng, that.lat));
      });
      window.setTimeout(function () {
        map.panTo(new BMap.Point(that.lng, that.lat));
      }, 2000);
      var marker = new BMap.Marker(point); // 创建标注
      map.addOverlay(marker); // 将标注添加到地图中
    },
    getData() {
      API.getCallus()
        .then((res) => {
          console.log(res);
          var status = res.code;
          if (status === 0) {
            this.infoData = res.page.list[0];
            this.joinData = res.page.list[2];
          }
        })
        .catch(() => {});
    },
    getJobs() {
      API.getMember()
        .then((res) => {
          console.log(res);
          var status = res.code;
          if (status === 0) {
            this.jobs = res.page.list;
          }
        })
        .catch(() => {});
    },
    handleChange(val) {
      console.log(val);
      if (val == NaN) {
        //   this.activeName = val;
        // } else {
        this.activeName = "-1";
      }
      console.log("activeName==", this.activeName);
    },
  },
};
</script>
<style lang="scss" scoped>
.mid_left_div {
  margin-top: 29rem;
  width: 50%;
  // display: flex;
  .mid_left_sub_div {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .p1 {
    letter-spacing: -1px;
    font-size: 2.6rem;
    color: black;
    text-align: start;
  }
  .p2 {
    @extend .p1;
    margin-top: 1rem;
    font-size: 3.6rem;
    // margin-left: 6.5rem;
    line-height: 4.3rem;
  }
  .p3 {
    @extend .p2;
    letter-spacing: normal;
    text-align: justify;
    margin-top: 13.7rem;
    font-size: 1.8rem;
    line-height: 3rem;
  }
}
.bus_div {
  p {
    line-height: 4rem;
    text-align: start;
  }
  .tel_div {
    margin-left: 5rem;
  }
  .addr_div {
    margin-left: 13rem;
  }
  .wechat_div {
    display: flex;
    margin-top: 7rem;
    img {
      width: 9.7rem;
      height: 9.7rem;
    }
    div {
      &:last-child {
        margin-left: 5rem;
      }
    }
    p {
      margin-top: 1rem;
      text-align: center;
    }
  }
}
.join_title_div {
  .p1 {
    text-align: start;
    font-size: 2.6rem;
  }
  .p2 {
    text-align: start;
    font-size: 3.6rem;
  }
}
.join_img_div {
  margin-top: 9rem;
  .img_div {
    width: 60rem;
    height: 40rem;
    img {
      width: auto;
      height: 100%;
    }
  }
  .info_div {
    margin-left: 10rem;
    width: 100%;
    .jobs_div {
      margin: 11rem 0 13rem;
      .title {
        letter-spacing: -1px;
        font-size: 2.8rem;
        line-height: 4.8rem;
        color: $color999;
        transition: all 0.5s;

        &::after {
          content: "";
          width: 1.4rem;
          height: 1.4rem;
          background: url("~@/../static/img/title-arrow-right-gray.png");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          display: inline-block;
          top: 0;
          bottom: 0;
          margin: auto 0 auto 0.5rem;
          transition: all 0.5s;
        }
        &.black {
          transition: all 0.5s;
          color: black;
          &::after {
            transition: all 0.5s;
            content: "";
            width: 1.4rem;
            height: 1.4rem;
            background: url("~@/../static/img/title-arrow-right.png");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            display: inline-block;
            top: 0;
            bottom: 0;
            margin: auto 0 auto 0.5rem;
          }
        }
      }
      .content {
        text-align: start;
        color: black;
        font-size: 2.2rem;
        line-height: 3.6rem;
        margin: 6rem 0;
      }
    }
    .p1 {
      text-align: start;
      font-size: 1.8rem;
      line-height: 3rem;
      /deep/ p {
        text-align: start;
        font-size: 1.8rem;
        line-height: 3rem;
      }
    }
    .p2 {
      margin-bottom: 6rem;
      /deep/ p {
        text-align: start;
        line-height: 4rem;
      }
    }
  }
}
.el-collapse {
  border-top: none;
  border-bottom: none;
}
/deep/ .el-collapse-item {
  width: fit-content;
}
/deep/ .el-collapse-item__arrow {
  display: none;
}
/deep/ .el-collapse-item__wrap {
  border-bottom: none;
}
/deep/ .el-collapse-item__header {
  border-bottom: none;
  cursor: $point_1, pointer;
}
@media (max-width: 1000px) {
  .mid_left_div {
    margin-top: 11rem;
    width: 100%;
    .mid_left_sub_div {
      width: 94.5%;
    }
    .p1 {
      letter-spacing: 0px;
      margin-left: 2.7rem;
      font-size: 1.7rem;
      line-height: 2.8rem;
    }
    .p2 {
      font-size: 2.3rem;
      line-height: 3.1rem;
    }
    .p3 {
      font-size: 1.1rem;
      line-height: 2.1rem;
      margin-top: 2.3rem;
    }
  }
  .width_div {
    // padding: 0 2.7rem;
    &.padding_top_170 {
      padding-top: 3.8rem;
    }
    &.padding_bottom_200 {
      padding-bottom: 7.6rem;
    }
    &.padding_bottom_350 {
      padding-bottom: 16rem;
    }
    &.flex {
      flex-direction: column;
    }
  }
  .bus_div {
    padding-left: 2.7rem;
    padding-right: 2.7rem;
    p {
      font-size: 1.7rem;
    }
    .tel_div {
      margin-left: 0;
      font-size: 1.1rem;
      /deep/ p {
        font-size: 1.1rem;
        line-height: 2.1rem;
      }
    }
    .addr_div {
      margin-left: 0;
      margin-top: 3.8rem;
      /deep/ p {
        font-size: 1.1rem;
        line-height: 1.9rem;
      }
    }
    .wechat_div {
      margin: 3rem auto 0;
      justify-content: center;
      div {
        &:last-child {
          margin-left: 3.5rem;
        }
      }
      p {
        margin-top: 0.2rem;
        font-size: 1.1rem;
        line-height: 1.9rem;
      }
      img {
        height: 7rem;
        width: 7rem;
      }
    }
  }
  .join_title_div {
    margin-left: 2.7rem;
    .p1 {
      font-size: 1.7rem;
      line-height: 3rem;
    }
    .p2 {
      font-size: 2.5rem;
      line-height: 4.1rem;
    }
  }
  .join_img_div {
    margin-top: 3rem;
    .img_div{
      width:100%;
      height:23rem;
    }
    img {
      width: 100%;
      height: 23rem;
    }
    .info_div {
      margin-left: 0;
      margin-top: 3.8rem;
      padding: 0 2.7rem;
      box-sizing: border-box;
      .p1 {
        font-size: 1.1rem;
        line-height: 1.9rem;
        /deep/ p {
          font-size: 1.1rem;
          line-height: 1.9rem;
        }
      }
      .p2 {
        margin-bottom: 3rem;
        /deep/ p {
          font-size: 1.1rem;
          line-height: 1.9rem;
        }
      }
      .jobs_div {
        margin: 3.8rem 0 5.7rem;
        .title {
          font-size: 1.7rem;
          line-height: 3.2rem;
          &::after {
            width: 7px;
            height: 8px;
            margin: auto 0 auto 3px;
          }
          &.black {
            &::after {
              width: 7px;
              height: 8px;
              margin: auto 0 auto 3px;
            }
          }
        }
        .content {
          font-size: 1.1rem;
          line-height: 2.1rem;
          margin: 3rem 0;
        }
      }
    }
  }
  /deep/ .el-collapse-item__header {
    height: auto;
  }
}
</style>